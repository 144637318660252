import React, { useState } from "react";
import logo from "../assets/logo.png";
import backgrounImage from "../assets/herobg.png";
import { useForm } from "react-hook-form";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Switch } from '@headlessui/react'

import { toast } from "react-toastify";

const LOGIN_URL = "/auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SignIn(props) {
  const { login, setUserData } = useAuth();
  const [enabled, setEnabled] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/trip/list";
  const [isShowPassword, setIsShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data);

    let payload = {
      email: data.email,
      password: data.password,
      roles: enabled ? "corporateapp" : "cooperate",
    };

    fetch(`${process.env.REACT_APP_BASEURL}auth/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // toast.success(data.message, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          // console.log(data);
          setUserData(data.user);
          login(data.token);
          navigate("/trip/list");
        } else {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-10 w-auto" src={logo} alt="Your Company" />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-main-dark">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-main-dark"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        {...register("email", { required: true })}
                      />
                    </div>
                    {errors.email && (
                      <p className="mt-2 text-sm text-main-danger">
                        Not a valid email address
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-main-dark"
                    >
                      Password
                    </label>
                    <div className="mt-2 relative">
                      <input
                        id="password"
                        name="password"
                        type={isShowPassword ? "text" : "password"}
                        {...register("password", { required: true })}
                      />
                      <span
                        onClick={() =>
                          setIsShowPassword((prevState) => !prevState)
                        }
                        className="absolute inset-y-0 mr-2 right-0 flex items-center"
                      >
                        {!isShowPassword ? (
                          <LockClosedIcon className="h-6 w-6 text-main-primary" />
                        ) : (
                          <LockOpenIcon className="h-6 w-6 text-main-primary" />
                        )}
                      </span>
                    </div>
                    {errors.password && (
                      <p className="mt-2 text-sm text-main-danger">
                        Not a valid password
                      </p>
                    )}
                  </div>

                  <div className="inline-flex items-center">
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? "bg-main-primary"
                          : "bg-main-base",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-main-primary focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        className={classNames(
                          enabled ? "translate-x-5"
                            : "translate-x-0",
                          "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-container-base shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      >
                        <span
                          className={classNames(
                            enabled ? "opacity-0 duration-100 ease-out"
                              : "opacity-100 duration-200 ease-in",
                            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path
                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span
                          className={classNames(
                            enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                          )}
                          aria-hidden="true"
                        >
                          <svg className="h-3 w-3 text-main-primary" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                          </svg>
                        </span>
                      </span>
                    </Switch>
                    <div className="ml-3 text-gray-500 text-sm">Staff</div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex justify-center w-full btn-primary"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover object-left scale-x-[-1]"
            src={backgrounImage}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
