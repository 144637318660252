/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import DriverPlaceholdeImage from "../assets/images/placeholders/driver-placeholder.png"
import { useNavigate, useParams } from "react-router-dom";
import GoogleAutoComplete from "../components/GoogleAutoComplete";

const roles = [
    { key: "0", value: 'ccooperate', label: 'Full Access' },
    { key: "1", value: 'crcallcenter', label: 'Call Center Representative' },
    { key: "2", value: 'cdispatcher', label: 'Transportation Coordinator' }
]

export default function CreateStaff(props) {
    const navigate = useNavigate();
    const { user, getToken } = useAuth();
    const { id } = useParams();
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [selected, setSelected] = useState([]);

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            address: ''
        }
    });

    // useEffect(() => {
    //     if (selected && selected.length) {
    //         setValue('roles', selected, {
    //             shouldValidate: true,
    //             // shouldDirty: true
    //         });
    //     } else {
    //         setValue('roles', null, {
    //             // shouldValidate: true,
    //             // shouldDirty: true
    //         });
    //     }
    // }, [selected]);

    function handleImageUpload(event) {
        const file = event.target.files[0];
        const imageUrl = URL.createObjectURL(file);
        setImageFile(file);
        setImageUrl(imageUrl);

    }

    const onSubmit = async (data) => {
        let payload = {
            ...data
        };

        const formData = new FormData();
        formData.append(`corporateapp[company]`, user.company._id);
        formData.append(`corporateapp[cooperate]`, user.cooperate ? user.cooperate._id : user._id);

        if (imageFile) {
            formData.append('newProfilePicture', imageFile, imageFile.name);
        }
        delete payload.image;

        if (id && !payload.password) {
            delete payload.password;
        }

        if (typeof payload.address === "object") {
            const address = { ...payload.address };
            payload.address = address.formatted_address;
            payload.latitude = address.geometry.location.lat();
            payload.longitude = address.geometry.location.lng();
        }

        Object.keys(payload).forEach((key) => {
            formData.append(`corporateapp[${key}]`, payload[key]);
        });

        let url = '';

        if (id) {
            url = `${process.env.REACT_APP_BASEURL}corporateapps/${id}`;
        } else {
            url = `${process.env.REACT_APP_BASEURL}corporateapps`
        }

        fetch(url, {
            method: id ? "PUT" : "POST",
            headers: {
                Authorization: 'JWT ' + JSON.parse(getToken()),
                // "Content-Type": "application/json",
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    navigate("/staff");
                    //   setOpen(true)
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const togglePassword = () => {
        setIsShowPassword(!isShowPassword);
    }

    const onKeyUpAddress = (address, val) => {
        console.log('onKeyUp: ', address, val);
        setValue(address, null);
      };

    useEffect(() => {
        if (!id) {
            return;
        }
        const getStaffDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASEURL}corporateapps/${id}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${JSON.parse(getToken())}`,
                        },
                    }
                );

                const res = await response.json();
                if (res) {
                    console.log('Response: ', res);
                    delete res.password;
                    reset({
                        displayName: res.displayName,
                        contactNumber: res.contactNumber,
                        address: res.address,
                        email: res.email,
                        roles: res.roles[0],
                        accountStatus: res.accountStatus
                    });
                    // reset(res);
                    setImageUrl(res.profileImageURL);
                    // const sRoles = roles.filter(r => res.roles.includes(r.value))
                    // setSelected(sRoles);
                }

            } catch (error) {
                console.log("Error:", error);
            }
        };
        setTimeout(() => {
            getStaffDetails();
        }, 2000);
    }, [id]);

    return (
        <>
            <div className="relative z-0 px-4 sm:px-6 lg:px-8 pt-4 pb-16 bg-main-base-100 gap-8 overflow-y-auto">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="card flex-1 divide-y divide-main-base flex flex-col gap-8 max-w-5xl"
                    autoComplete="off"
                >
                    <div>
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-main-dark">
                                {id ? 'Edit' : 'Add'}
                            </h3>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="displayName" className="block text-sm font-medium text-main-secondary">Display Name</label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        {...register("displayName", { required: true })}
                                        required=""

                                        id="displayName" />
                                </div>
                                {errors.displayName && (
                                    <p className="mt-2 text-xs text-main-danger">
                                        Display Name is Required
                                    </p>
                                )}
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="contact-number" className="block text-sm font-medium text-main-secondary">Contact
                                    Number</label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        {...register("contactNumber", { required: true })}
                                        id="contact-number"
                                    />
                                    {errors.contactNumber && (
                                        <p className="mt-2 text-xs text-main-danger">
                                            Contact Number is Required
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="address" className="block text-sm font-medium text-main-secondary">Address</label>
                                <div className="mt-1">
                                    <Controller
                                        name="address"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <GoogleAutoComplete
                                            defaultValue={value}
                                            onPlaceSelected={(place) => {
                                              onChange(place);
                                            }}
                                            onKeyUp={(e) =>
                                              onKeyUpAddress("jobOriginAddress", e.target.value)
                                            }
                                          />
                                        )}
                                    />
                                    {errors.address && (
                                        <p className="mt-2 text-xs text-main-danger">
                                            Address is Required
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <label htmlFor="image" className="block text-sm font-medium text-main-secondary">Profile
                                    Image</label>
                                <div className="relative overflow-hidden rounded-full inline-flex mt-1">

                                    <img src={imageUrl || DriverPlaceholdeImage} alt=""
                                        className="relative h-32 w-32 rounded-full" />

                                    <label htmlFor="image"
                                        className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100">
                                        <span>Change</span>
                                        <span className="sr-only"> user photo</span>
                                        <input
                                            id="file-upload"
                                            name="file-upload"
                                            type="file"
                                            accept="image/*"
                                            {...register("image", { required: imageUrl ? false : true })}
                                            onChange={handleImageUpload}
                                            className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-main-base opacity-0" />
                                    </label>

                                </div>
                                {errors.image && (
                                    <p className="mt-2 text-xs text-main-danger">
                                        Profile Image is Required
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="pt-8">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-main-dark">
                                Legal Information
                            </h3>
                            <p className="mt-1 text-sm text-main-light">
                                Use a permanent address where you can receive mail.
                            </p>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="email" className="block text-sm font-medium text-main-secondary">Email
                                    Address</label>
                                <div className="mt-1 relative">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <svg className="h-5 w-5 text-main-light" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path
                                                d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                                            <path
                                                d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                                        </svg>
                                    </div>
                                    <input
                                        {...register("email", { required: true })}
                                        type="email"
                                        className="pl-10"
                                        name="email"
                                        id="email"
                                        autoComplete="emial"
                                    />
                                </div>
                                {errors.email && (
                                    <p className="mt-2 text-xs text-main-danger ">
                                        Email is required
                                    </p>
                                )}

                                {/* <div *ngIf="form.email.errors && submitted">
                                <div *ngIf="form.email.errors.required" className="">
                                    <p className="mt-2 text-xs text-main-danger">
                                        Email is Required
                                    </p>
                                </div>
                                <div *ngIf="form.email.errors.email" className="">
                                    <p className="mt-2 text-xs text-main-danger">
                                        Email is not Valid
                                    </p>
                                </div>
                            </div> */}

                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="password" className="block text-sm font-medium text-main-secondary">Password</label>
                                <div className="mt-1 relative">
                                    <div onClick={togglePassword} className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                                        {isShowPassword ? (
                                            <LockOpenIcon className="h-5 w-5 text-main-primary" />
                                        ) : (
                                            <LockClosedIcon className="h-5 w-5 text-main-primary" />
                                        )}
                                    </div>

                                    {/* <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                                        <svg className="h-5 w-5 text-main-light" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg>
                                        <svg className="h-5 w-5 text-main-light" xmlns="http://www.w3.org/2000/svg"
                                            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg>
                                    </div> */}
                                    <input
                                        {...register("password", { required: id ? false : true })}
                                        type={isShowPassword ? 'text' : 'password'}
                                        className="pr-10"
                                        name="password"
                                        id="password"
                                    />
                                </div>
                                {errors.password && (
                                    <p className="mt-2 text-xs text-main-danger ">
                                        Password is required
                                    </p>
                                )}
                                {/* <div *ngIf="form.password.errors && submitted">
                                <div *ngIf="form.password.errors.required" className="">
                                    <p className="mt-2 text-xs text-main-danger ">
                                        Password is required
                                    </p>
                                </div>
                                <div *ngIf="form.password.errors.minlength" className="">
                                    <p className="mt-2 text-xs text-main-danger">
                                        Password must be atleast 6 characters
                                    </p>

                                </div>
                                <div *ngIf="form.password.errors.maxlength" className="">
                                    <p className="mt-2 text-xs text-main-danger">
                                        Password must be maximum of 16 characters
                                    </p>

                                </div>
                            </div> */}

                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="role-type" className="block text-sm font-medium text-main-secondary">Role
                                    Type</label>
                                <div className="mt-1">
                                    {/* <MultiSelect
                                        {...register("roles", { required: true })}
                                        options={roles}
                                        value={selected}
                                        onChange={setSelected}
                                        labelledBy={"Select"}
                                        isCreatable={false}
                                        hasSelectAll={false}
                                        disableSearch={true}
                                        overrideStrings={{
                                            allItemsAreSelected: 'All Roles'
                                        }}
                                    /> */}
                                    <select
                                        {...register("roles", { required: true })}
                                        id="roles"
                                    >
                                        <option value="">-- Select Role Type --</option>
                                        <option value='ccooperate'>Full Access</option>
                                        <option value='crcallcenter'>Call Center Representative</option>
                                        <option value='cdispatcher'>Transportation Coordinator</option>
                                    </select>
                                    {errors.roles && (
                                        <p className="mt-2 text-xs text-main-danger ">
                                            Role is Required
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="account-status" className="block text-sm font-medium text-main-secondary">Account
                                    Status</label>
                                <div className="mt-1">
                                    <select
                                        {...register("accountStatus", { required: true })}
                                        id="accountStatus"
                                    >
                                        <option value="">-- Select Account Status --</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                    {errors.accountStatus && (
                                        <p className="mt-2 text-xs text-main-danger ">
                                            Account Status is required
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-8">
                        <div className="flex justify-end">
                            <button type="submit" className="ml-3 btn-primary">{id ? 'Update' : 'Create'}</button>
                        </div>
                    </div>
                </form >
            </div>
        </>
    );
}