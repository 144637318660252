/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import Moment from "react-moment";
import { titlecase } from "../utils/common";


export default function Notifications(props) {
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const { getToken, user } = useAuth();

    const getNotifications = async () => {
        const token = JSON.parse(getToken());
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASEURL}notifications`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${token}`,
                    }
                }
            );

            const data = await response.json();
            setNotifications(data);
            setFilteredNotifications(data);
        } catch (error) {
            console.log("Error:", error);
        }
    };

    useEffect(() => {
        if(user) {
            getNotifications();
        }
    }, [user]);

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== "") {
            const results = notifications.filter((item) => {
                return item.status
                    .toLowerCase()
                    .includes(keyword.toLowerCase()) || 
                    item.text
                    .toLowerCase()
                    .includes(keyword.toLowerCase());
            });
            setFilteredNotifications(results);
        } else {
            setFilteredNotifications(notifications);
        }
    };

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8 py-4 ">
                <div className="flex flex-wrap justify-between items-center gap-4">
                    <h3 className="text-lg font-medium leading-6 text-main-dark">
                        Notifications
                    </h3>
                    <div className="flex-1 flex justify-center">
                        <span className="text-main-light text-sm whitespace-nowrap">
                            {/* Showing {{(notifications | filter:search).length}} entries */}
                        </span>
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                        <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" aria-hidden="true">
                                <svg className="mr-3 h-4 w-4 text-main-light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                    fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                        clipRule="evenodd" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                name="search"
                                id="search"
                                className="pl-9"
                                placeholder="Search"
                                autoComplete="off"
                                onChange={filter}
                            />
                        </div>
                    </div>
                </div>
                <ul className="h-screen mt-4 flex-1 divide-y divide-main-base overflow-y-auto">
                {filteredNotifications.map((notification, index) => (
                    <li key={index} >
                        <div className="group relative flex items-center py-4 px-4">
                            <div className="-m-1 block flex-1 p-1">
                                <div className="absolute inset-0 group-hover:bg-main-base-50" aria-hidden="true">
                                </div>
                                <div
                                    className="relative flex min-w-0 flex-1 items-start gap-2 text-xs text-main-secondary">
                                    {/* <div
                                        className="h-8 w-8 rounded-full overflow-hidden object-cover flex-shrink-0">
                                        <img src={{notification?.user?.profileImageURL}}
                                className="h-full w-full" />
                                    </div> */}
                                    {/* <div className="h-8 w-8 rounded-full overflow-hidden object-cover flex-shrink-0">
                                        <img
                                            className="h-full w-full"
                                            src="/assets/images/placeholders/driver-placeholder.png"
                                            alt=""
                                        />
                                    </div> */}
                                    <div className="flex flex-col w-full">
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <div className="font-bold">
                                                    {/* {{notification?.user?.displayName | capitalize}} */}
                                                </div>
                                            </div>
                                            <div className="inline-flex items-center rounded-md bg-badge-primary px-1.5 py-0.5 text-xs font-medium text-white">
                                                {titlecase(notification.status)}
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-main-light">
                                                {notification.text}
                                            </p>
                                            <p className="text-main-light">
                                                {notification.created && user ? (
                                                    <Moment
                                                        format="MM/DD/YYYY HHmm"
                                                        tz={user.company.timeZone}
                                                    >
                                                        {notification.created}
                                                    </Moment>
                                                ) : (
                                                    "N/A"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
                </ul>
                {(notifications && notifications.length === 0) && (
                    <div>
                    <div className="relative block w-full rounded-lg border-2 border-dashed border-main-base p-12 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                            className="mx-auto h-12 w-12 text-main-light">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z" />
                        </svg>
                        <span className="mt-2 block font-medium text-main-dark">No notifications to show</span>
                    </div>
                </div>
                )}
                
            </div>
        </>
    );
}
