import React, { useEffect } from "react";
import { useNavigate, Navigate, useLocation, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import SideNav from "./SideNav";
import Header from "./Header";

const PrivateRoutes = () => {
  const { auth, getToken, user, setUserData, login, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const token = getToken();

  useEffect(() => {
    if (token) validateToken();
  }, [location.pathname]);

  const validateToken = async () => {
    try {
      const cleanedToken = token.replace(/^"(.*)"$/, "$1");
      const result = await fetch(`${process.env.REACT_APP_BASEURL}users/me`, {
        method: "GET",
        headers: {
          Authorization: `JWT ${cleanedToken}`,
        },
      });
      const response = await result.json();
      if (response.success && response.user) {
        // Token is valid
        setUserData(response.user);
        // console.log("token before login", token);
        login(cleanedToken);
      } else {
        // Token is invalid
        logout();
      }
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };

  return token ? (
    <div className="flex h-screen overflow-hidden">
      <SideNav />
      <div className="flex flex-1 flex-col overflow-hidden">
        <Header />
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default PrivateRoutes;
