import React from "react";
import { Controller,useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleAutoComplete from "../components/GoogleAutoComplete";
import useAuth from "../hooks/useAuth";

export default function TrisLegComponent(props) {
    const {  watch, setValue, control, errors, register,restrictRadius,radius } = props;
    const { user, getToken } = useAuth();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "tripLegs",
    });
    
    const handleAddTripLeg = () => {
        const firstLeg = watch();
        const appointmentTime = new Date(firstLeg.appointmentTime);
        appointmentTime.setHours(appointmentTime.getHours() + 1);
        appointmentTime.setHours(22);
        appointmentTime.setMinutes(0);
    
        append({
          jobOriginAddress:
            typeof firstLeg.jobDestinationAddress === "object"
              ? firstLeg.jobDestinationAddress.formatted_address
              : firstLeg.jobDestinationAddress,
    
          jobOriginLatitude:
            typeof firstLeg.jobDestinationAddress === "object"
              ? firstLeg.jobDestinationAddress.geometry.location.lat()
              : firstLeg.jobDestinationLatitude,
    
          jobOriginLongitude:
            typeof firstLeg.jobDestinationAddress === "object"
              ? firstLeg.jobDestinationAddress.geometry.location.lng()
              : firstLeg.jobDestinationLongitude,
    
          jobDestinationAddress:
            typeof firstLeg.jobOriginAddress === "object"
              ? firstLeg.jobOriginAddress.formatted_address
              : firstLeg.jobOriginAddress,
    
          jobDestinationLatitude:
            typeof firstLeg.jobOriginAddress === "object"
              ? firstLeg.jobOriginAddress.geometry.location.lat()
              : firstLeg.jobOriginLatitude,
    
          jobDestinationLongitude:
            typeof firstLeg.jobOriginAddress === "object"
              ? firstLeg.jobOriginAddress.geometry.location.lng()
              : firstLeg.jobOriginLongitude,
    
          scheduleTime: appointmentTime,
          isWillCall: true,
          originAddressAdditional: {
            floor:  firstLeg.destinationAddressAdditional.floor,
            room: firstLeg.destinationAddressAdditional.room,
            aptOrSuite: firstLeg.destinationAddressAdditional.aptOrSuite,
            place: firstLeg.destinationAddressAdditional.place,
            phone: firstLeg.destinationAddressAdditional.phone
          },
          destinationAddressAdditional: {
            floor: firstLeg.originAddressAdditional.floor,
            room: firstLeg.originAddressAdditional.room,
            aptOrSuite: firstLeg.originAddressAdditional.aptOrSuite,
            place: firstLeg.originAddressAdditional.place,
            phone: firstLeg.originAddressAdditional.phone
          },
          companyNote: ''
        });
    };
    
    const handleWillCallCheck = (leg) => {
        const appointmentTime = new Date(watch(`${leg}.scheduleTime`));
        appointmentTime.setHours(22);
        appointmentTime.setMinutes(0);
        setValue(`${leg}.scheduleTime`, appointmentTime);
      };

    return (
      <>
                    {watch("jobOriginAddress") &&
                        watch("jobDestinationAddress") && (
                            <div className="pt-8">
                                <div className="sm:col-span-6">
                                    <div className="bg-main-base-100 border-2 border-dashed border-main-base p-4 rounded-lg">
                                        {fields.map((field, index) => (
                                            <div key={field.id} className="mb-6 space-y-6">
                                                <div className="flex flex-wrap gap-6 items-center justify-between">
                                                    <h3 className="text-lg font-medium leading-6 text-main-dark">
                                                        {/* Leg {index + 1} */}
                                                        Leg/Return
                                                    </h3>
                                                    <div>
                                                        <button
                                                            className="btn-danger"
                                                            onClick={() => remove(index)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                    <div className="sm:col-span-2">
                                                        <label
                                                            htmlFor={`tripLegs.${index}.scheduleTime`}
                                                            className="block text-sm font-medium text-main-secondary"
                                                        >
                                                            Schedule Time
                                                        </label>
                                                        <div className="mt-1 ">
                                                            <Controller
                                                                control={control}
                                                                rules={{ required: true }}
                                                                name={`tripLegs.${index}.scheduleTime`}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        className="disabled:bg-main-base"
                                                                        placeholderText="Select date"
                                                                        showTimeSelect
                                                                        disabled={
                                                                            watch(
                                                                                `tripLegs.${index}.isWillCall`
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        // minDate={new Date()}
                                                                        onChange={(date) =>
                                                                            field.onChange(date)
                                                                        }
                                                                        selected={field.value}
                                                                        timeIntervals={15}
                                                                        timeFormat="HH:mm"
                                                                        dateFormat="MM/dd/yyyy HHmm"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {errors.tripLegs &&
                                                            errors.tripLegs[index].scheduleTime && (
                                                                <p className="mt-2 text-xs text-main-danger">
                                                                    Entet the Schedule Time
                                                                </p>
                                                            )}
                                                    </div>

                                                    <div className="sm:col-span-2">
                                                        <div className="flex items-center gap-4 mt-7">
                                                            <div>
                                                                <Controller
                                                                    control={control}
                                                                    rules={{ required: false }}
                                                                    name={`tripLegs.${index}.isWillCall`}
                                                                    render={({
                                                                        field: { onChange, value },
                                                                    }) => (
                                                                        <input
                                                                            type="checkbox"
                                                                            name="willCall"
                                                                            defaultChecked={value}
                                                                            id="willCall"
                                                                            className="willCall"
                                                                            onChange={(e) => {
                                                                                onChange(e.target.checked);
                                                                                handleWillCallCheck(
                                                                                    `tripLegs.${index}`
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <label
                                                                htmlFor="willCall"
                                                                className="mt-1 inline text-sm font-medium text-main-secondary"
                                                            >
                                                                Will Call
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-3">
                                                        <label
                                                            htmlFor={`tripLegs.${index}.jobOriginAddress`}
                                                            className="block text-sm font-medium text-main-secondary"
                                                        >
                                                            Pickup Address
                                                        </label>
                                                        <div className="mt-1">
                                                            {user && (
                                                                <Controller
                                                                    name={`tripLegs.${index}.jobOriginAddress`}
                                                                    control={control}
                                                                    rules={{ required: true }}
                                                                    render={({
                                                                        field: { onChange, value },
                                                                    }) => (
                                                                        <GoogleAutoComplete
                                                                            defaultValue={value}
                                                                            restrictRadius={restrictRadius}
                                                                            radius={radius}
                                                                            onPlaceSelected={(place) => {
                                                                                onChange(place);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                        {errors.tripLegs &&
                                                            errors.tripLegs[index].jobOriginAddress && (
                                                                <p className="mt-2 text-xs text-main-danger">
                                                                    Enter the Pickup Address
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="sm:col-span-3">
                                                        <label
                                                            htmlFor={`tripLegs.${index}.jobDestinationAddress`}
                                                            className="block text-sm font-medium text-main-secondary"
                                                        >
                                                            Dropoff Address
                                                        </label>
                                                        <div className="mt-1">
                                                            {user && (
                                                                <Controller
                                                                    name={`tripLegs.${index}.jobDestinationAddress`}
                                                                    control={control}
                                                                    rules={{ required: true }}
                                                                    render={({
                                                                        field: { onChange, value },
                                                                    }) => (
                                                                        <GoogleAutoComplete
                                                                            defaultValue={value}
                                                                            restrictRadius={restrictRadius}
                                                                            radius={radius}
                                                                            onPlaceSelected={(place) => {
                                                                                onChange(place);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                        {errors.tripLegs &&
                                                            errors.tripLegs[index]
                                                                .jobDestinationAddress && (
                                                                <p className="mt-2 text-xs text-main-danger">
                                                                    Enter the Dropoff Address
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className="sm:col-span-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 items-end">
                                                        <div className="sm:col-span-2">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                Floor
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.originAddressAdditional.floor`, {
                                                                        required: false,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                Room
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.originAddressAdditional.room`, {
                                                                        required: false,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                APT/SUITE
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.originAddressAdditional.aptOrSuite`, {
                                                                        required: false,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-3">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                                                                    {" "}
                                                                    Home / Facility / Physician Name
                                                                </span>
                                                                <span className="ml-1 text-main-primary/90 font-normal">
                                                                    (Required)
                                                                </span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.originAddressAdditional.place`, {
                                                                        required: true,
                                                                    })}
                                                                />
                                                                {errors.tripLegs &&
                                                                    errors.tripLegs[index]
                                                                        .originAddressAdditional?.place && (
                                                                        <p className="mt-2 text-xs text-main-danger">
                                                                            Enter the Home/Facility/Physician Name
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-3">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                                                                    {" "}
                                                                    Phone Number
                                                                </span>
                                                                <span className="ml-1 text-main-primary/90 font-normal">
                                                                    (Required)
                                                                </span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.originAddressAdditional.phone`, {
                                                                        required: true,
                                                                    })}
                                                                />
                                                                {errors.tripLegs &&
                                                                    errors.tripLegs[index]
                                                                        .originAddressAdditional?.phone && (
                                                                        <p className="mt-2 text-xs text-main-danger">
                                                                            Enter the Phone Number
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 items-end">
                                                        <div className="sm:col-span-2">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                Floor
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.destinationAddressAdditional.floor`, {
                                                                        required: false,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                Room
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.destinationAddressAdditional.room`, {
                                                                        required: false,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                APT/SUITE
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.destinationAddressAdditional.aptOrSuite`, {
                                                                        required: false,
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-3">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                                                                    {" "}
                                                                    Home / Facility / Physician Name
                                                                </span>
                                                                <span className="ml-1 text-main-primary/90 font-normal">
                                                                    (Required)
                                                                </span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.destinationAddressAdditional.place`, {
                                                                        required: true,
                                                                    })}
                                                                />
                                                                {errors.tripLegs &&
                                                                    errors.tripLegs[index]
                                                                        .destinationAddressAdditional?.place && (
                                                                        <p className="mt-2 text-xs text-main-danger">
                                                                            Enter the Home/Facility/Physician Name
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-3">
                                                            <label className="block text-sm font-medium text-main-secondary">
                                                                <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                                                                    {" "}
                                                                    Phone Number
                                                                </span>
                                                                <span className="ml-1 text-main-primary/90 font-normal">
                                                                    (Required)
                                                                </span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <input
                                                                    type="text"
                                                                    {...register(`tripLegs.${index}.destinationAddressAdditional.phone`, {
                                                                        required: true,
                                                                    })}
                                                                />
                                                                {errors.tripLegs &&
                                                                    errors.tripLegs[index]
                                                                        .destinationAddressAdditional?.phone && (
                                                                        <p className="mt-2 text-xs text-main-danger">
                                                                            Enter the Phone Number
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-3">
                                                        <label
                                                            htmlFor="legNotes"
                                                            className="block text-sm font-medium text-main-secondary"
                                                        >
                                                            Driver Notes
                                                        </label>
                                                        <div className="mt-1">
                                                            <textarea
                                                                id="legNotes"
                                                                {...register(`tripLegs.${index}.companyNote`, { required: false })}
                                                                className=" h-16"
                                                            ></textarea>
                                                        </div>
                                                        {/* {errors.companyNote && (
                          <p className="mt-2 text-xs text-main-danger">
                            Enter the Driver Notes
                          </p>
                        )} */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {fields.length < 1 && (
                                            <button
                                                className="btn-primary"
                                                type="button"
                                                onClick={handleAddTripLeg}
                                            >
                                                Add Leg/Return
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
        </>
    );
}