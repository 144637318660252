import { usePlacesWidget } from "react-google-autocomplete";

const GoogleAutoComplete = (props) => {
    let options = {
        types: ["geocode", "establishment"],
        //   componentRestrictions: { country: "ru" },
    }
    if (props.restrictRadius)
        options = { options, ...setupOptions(props.radius) }


    const { ref } = usePlacesWidget({
        // apiKey: process.env.REACT_APP_MAP_KEY,
        onPlaceSelected: (place) => {
            // setting formated address manully. as picker is not setting same string to input
            ref.current.value = place.formatted_address;
            props.onPlaceSelected(place);
        },
        options: options
    });

    return <input type="text" ref={ref} onKeyUp={props.onKeyUp} defaultValue={props.defaultValue} />;
};


const setupOptions = (radius) => {
    const center = radius.center;
    const val = (radius.miles * 1.609344) / 100

    // Create a bounding box with sides ~100km away from the center point - 1.0 means ~100KM
    const defaultBounds = {
        north: center.lat + val,
        south: center.lat - val,
        east: center.lng + val,
        west: center.lng - val,
    };

    const options = {
        bounds: defaultBounds,
        // componentRestrictions: { country: 'US' },
        strictBounds: true
        // types: ['(cities)']
        // types: ['geocode']  // 'establishment' / 'address' / 'geocode'
    }
    return options;
}

export default GoogleAutoComplete