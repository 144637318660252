import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { getstandingOrderDaysAsObject } from "../utils/common";
import Moment from "react-moment";
import moment from "moment-timezone";
import { titlecase } from "../utils/common";


export default function StandingOrderDetailModal({ trip, handleClose }) {

    const { getToken, user } = useAuth();
    const [tripsList, setTripList] = useState([]);
    const [data, setData] = useState([]);
    const [search, SetSearch] = useState('');
    const [standingOrderDays, setStandingOrderDays] = useState({});

    useEffect(() => {
        setStandingOrderDays(getstandingOrderDaysAsObject())
        getStandingOrdersDetails();
    }, []);

    const getStandingOrdersDetails = async () => {
        const token = JSON.parse(getToken());
        let queryParams = `status=associatestandingorder&so=${trip._id}&scheduleTime=${moment.tz(user.company.timeZone).startOf('day').toISOString()}`
        
        const url = `${process.env.REACT_APP_BASEURL}assigns/getmanifesttrips?` + queryParams;

        try {
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${token}`,
                    }
                }
            );

            const data = await response.json();
            setTripList(data);
            setData(data);
        } catch (error) {
            console.log("Error:", error);
        }
    };

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== "") {
          const results = data.filter((item) => {
            return item.tripId
              .toLowerCase()
              .includes(keyword.toLowerCase());
          });
          setTripList(results);
        } else {
          setTripList(data);
        }
      };

    return (
        <div className="fixed z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-main-light bg-opacity-75 transition-opacity backdrop-blur-sm"></div>
            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <div className="pointer-events-auto w-screen max-w-7xl">
                            <div className="flex h-full flex-col bg-container-base shadow-xl overflow-y-auto">
                                <div className="p-6 border-b border-main-base">
                                    <div className="grid md:grid-cols-3 gap-8 items-center">
                                        <div>
                                            <h4 className="text-xs text-main-light"> Member Name</h4>
                                            <h2 className="text-xs font-medium text-main-dark" id="slide-over-title">
                                                {trip.priorityClient.displayName}
                                            </h2>
                                        </div>
                                        <div>
                                            <h4 className="text-xs text-main-light">Recurring Days</h4>
                                            <div className="flex gap-1">
                                                {trip && (
                                                    <>
                                                        {trip.recurringDays.map((item, index) => (
                                                            <span key={index} className="inline-flex items-center rounded-md bg-badge-primary px-1.5 py-0.5 text-[9px] font-medium text-white">
                                                                {standingOrderDays[item.day]}
                                                            </span>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <h4 className="text-xs text-main-light">Period</h4>
                                            <div className="text-xs font-medium text-main-dark" id="slide-over-title">
                                                <div className="flex items-start gap-2">
                                                    <div className="text-main-primary hidden md:block">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                                            stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                                        </svg>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <div className="font-bold text-main-dark">
                                                            {trip.scheduleTime && user ? (
                                                                <Moment
                                                                    format="MM/DD/YYYY"
                                                                    tz={user.company.timeZone}
                                                                >
                                                                    {trip.scheduleTime}
                                                                </Moment>
                                                            ) : (
                                                                "N/A"
                                                            )} to &nbsp;
                                                            {trip.recurringEndDate && user ? (
                                                                <Moment
                                                                    format="MM/DD/YYYY"
                                                                    tz={user.company.timeZone}
                                                                >
                                                                    {trip.recurringEndDate}
                                                                </Moment>
                                                            ) : (
                                                                "N/A"
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute top-6 right-6 flex h-7 items-center">
                                            <button
                                                onClick={() => handleClose()}
                                                type="button"
                                                className="rounded-md bg-container-base text-main-light hover:text-main-secondary focus:ring-2 focus:ring-main-primary"
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                                    <path stroke-linecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 bg-main-base-100">
                                    <div className="gap-4 p-2 bg-[#f6f7fb] sticky left-0 top-0 z-20 w-full">
                                        <div className="flex flex-wrap gap-2 items-center justify-between">
                                            <div className="flex gap-2 items-center">
                                                <div className="relative">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" aria-hidden="true">
                                                        <svg className="mr-3 h-4 w-4 text-main-light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                            fill="currentColor" aria-hidden="true">
                                                            <path fillRule="evenodd"
                                                                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                                                clipRule="evenodd" />
                                                        </svg>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="search"
                                                        id="search"
                                                        className="pl-9"
                                                        placeholder="Search"
                                                        onChange={filter}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-1 flex justify-center">
                                                <span className="text-main-light text-sm whitespace-nowrap">
                                                    Showing {tripsList.length} entries
                                                </span>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="px-2 overflow-x-auto min-w-full">
                                        <div className="flow-root">
                                            <div className="">
                                                <div className="inline-block min-w-full align-middle">
                                                    <table className="min-w-full border-separate border-spacing-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    Trip ID ({tripsList && tripsList.length ? tripsList.length : 0})</th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    Member</th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    Date</th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    PU Time </th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    APT Time</th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    Pick-Up</th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    Drop-off</th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    LOS</th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                    Status</th>
                                                                <th scope="col"
                                                                    className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tripsList.map(trip => (
                                                                <tr
                                                                    key={trip.tripId}
                                                                    className={`hover:bg-main-base-100 ${trip.diffFromToday <= 0 ? 'bg-red-100' : (trip.diffFromToday > 0 && trip.diffFromToday <= 7) ? 'bg-orange-100' : ''}`}
                                                                >
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        {/* Trip ID */}
                                                                        {trip.tripId}
                                                                    </td>
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        <div className="min-w-[100px] max-w-[100px] truncate">
                                                                            {trip.priorityClient ? (trip.priorityClient.displayName) : 'N/A'}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        <div className="min-w-[100px] max-w-[100px] truncate">
                                                                            <b>
                                                                                {(trip.scheduleTime && user) && (
                                                                                    <Moment
                                                                                        format="ddd"
                                                                                        tz={user.company.timeZone}
                                                                                    >
                                                                                        {trip.scheduleTime}
                                                                                    </Moment>
                                                                                )}
                                                                            </b> &nbsp;
                                                                            {(trip.scheduleTime && user) && (
                                                                                <Moment
                                                                                    format="MM/DD/YYYY"
                                                                                    tz={user.company.timeZone}
                                                                                >
                                                                                    {trip.scheduleTime}
                                                                                </Moment>
                                                                            )}

                                                                            {/* {{ trip.scheduleTime | date:'MM/DD/YYYY' }} */}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        <div className="min-w-[60px] max-w-[60px] truncate">
                                                                            {/* {{ trip.scheduleTime | date:'HHmm' }} */}
                                                                            {(trip.scheduleTime && user) && (
                                                                                <Moment
                                                                                    format="HHmm"
                                                                                    tz={user.company.timeZone}
                                                                                >
                                                                                    {trip.scheduleTime}
                                                                                </Moment>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        <div className="min-w-[60px] max-w-[60px] truncate">
                                                                            {/* {{ trip.appointmentTime | date : "HHmm" }} */}
                                                                            {(trip.appointmentTime && user) && (
                                                                                <Moment
                                                                                    format="HHmm"
                                                                                    tz={user.company.timeZone}
                                                                                >
                                                                                    {trip.appointmentTime}
                                                                                </Moment>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        <div className="min-w-[240px] max-w-[240px] truncate">
                                                                            {trip.jobOriginAddress}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        <div className="min-w-[240px] max-w-[240px] truncate">
                                                                            {trip.jobDestinationAddress}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        <div className="min-w-[80px] max-w-[80px] truncate">
                                                                            {trip.tripRequirement}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-[5px] text-xs border-b border-[#f2f2f2] text-[#333333]">
                                                                        <div className="inline-flex bg-main-primary rounded-md px-1 text-[10px] font-semibold leading-5 text-white">
                                                                            {titlecase(trip.status)}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {tripsList.length === 0 && (
                                        <div className="relative block w-full rounded-lg border-2 border-dashed border-main-base p-12 text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="mx-auto h-12 w-12 text-main-light">
                                                {/* SVG path */}
                                            </svg>
                                            <span className="mt-2 block font-medium text-main-dark">No trips to show</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};
