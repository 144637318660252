import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./SignIn";
import TripList from "./Trips/list";
import NotFound from "./NotFound";
import History from "./Trips/history";
import Create from "./Trips/create";
import Profile from "./Profile";
import StandingOrderList from "./StandingOrders/StandingOrdersList";
import StaffList from "./Staff/StaffList";
import CreateStaff from "./Staff/CreateStaff";
import Tokenization from "./Tokenization";
import PrivateRoutes from "./components/PrivateRoutes";
import useAuth from "./hooks/useAuth";
import PublicRoutes from "./components/PublicRoutes";
import { ToastContainer } from "react-toastify";
import Socket from "./shared/socketService";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Notifications from "./Notifications";


export default function App() {
  const { user, getToken } = useAuth();
  const [socket, setSocket] = useState(null);
  let notificationBell = new Audio('/notify.mp3');

  useEffect(() => {
    if (user && !socket) {
      const mySocket = Socket(getToken());
      setSocket(mySocket);
    }

    if (!user && socket) {
      socket.off("notifyCorporatePanel");
      socket.disconnect();
      setSocket(null);
    }

    return () => {};
  }, [user]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("Connected to the server");
      });

      socket.on("disconnect", () => {
        console.log("Disconnected from the server");
      });

      // socket.on("chatMessage", (data) => {
      //   console.log("From the server", data);
      // });

      socket.on("notifyCorporatePanel", (data) => {
        console.log("notifyCorporatePanel", data);
        toast.info(data.text, {
          position: "bottom-right",
          style: {
            color: "#3d5e3d", // Set your desired text color here
            // backgroundColor: 'yellow', // Set the background color if needed
            // iconColor: 'red', // Icon color
          },
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          progressStyle: { background: "#3d5e3d" },
        });
        notificationBell.play();
      });

      return () => {
        socket.off("notifyCorporatePanel");
      };
    }
  }, [socket]);

  return (
    <>
      <Routes>
        <Route>
          {/* public routes */}
          <Route element={<PublicRoutes />}>
            <Route path="signin" element={<SignIn />} />
          </Route>

          {/* we want to protect these routes */}
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Navigate to="/trip/list" replace />} />
            <Route path="trip/list" element={<TripList />} />
            <Route path="/trip/history" element={<History />} />
            <Route path="/trip/create" element={<Create />} />
            <Route path="/standing-orders" element={<StandingOrderList />} />
            <Route path="/staff" element={<StaffList />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/staff/add" element={<CreateStaff />} />
            <Route path="/trip/:id/edit" element={<Create />} />
            <Route path="/staff/:id/edit" element={<CreateStaff />} />
            <Route path="/notifications" element={<Notifications />} />
          </Route>

          <Route path="auth-token/:token" element={<Tokenization />} />
          {/* catch all */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}
