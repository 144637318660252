import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function Header(props) {
  const { logout, user, setSidebarOpen, logoutModalOpen, setLogoutModalOpen } =
    useAuth();
  const navigate = useNavigate();

  const handleLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const headingText = props.headingText;
  return (
    <>
      <header className=" lg:hidden w-full shadow border-b border-main-base bg-header-base">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="relative flex h-16 justify-between">
            <div className="flex items-center lg:hidden">
              <button
                type="button"
                onClick={() => setSidebarOpen(true)}
                className="inline-flex items-center justify-center rounded-md p-2 text-header-light hover:bg-header-hover-btn hover:text-header-hover-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-header-primary"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>

                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
              <span>{headingText}</span>
            </div>
            <div className="flex items-center">
              <div>
                <Menu as="div" className="relative inline-block">
                  <Menu.Button className="rounded-full bg-header-primary p-1 text-white focus:outline-none focus:ring-2 focus:ring-header-primary focus:ring-offset-2">
                    <PlusIcon className="w-6 h-6" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute right-0 z-20 mt-2 w-56 origin-top-right divide-y divide-main-base rounded-md bg-header-base shadow-lg ring-1 ring-main-base focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex="-1"
                    >
                      <div className="py-1" role="none">
                        <Link
                          to="/trip/create"
                          className="text-header-light hover:text-header-hover-light group flex items-center px-4 py-2 text-sm"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-0"
                        >
                          <PlusIcon className="mr-3 h-5 w-5 text-main-primary" />
                          Create New Trip
                        </Link>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="relative ml-3">
                <Menu as="div">
                  <Menu.Button
                    className="flex rounded-full items-center bg-header-base text-sm focus:outline-none ring-2 ring-header-primary ring-offset-2 xl:pr-2"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open user menu</span>
                    {user ? (
                      <img
                        className="h-8 w-8 rounded-full object-scale-down"
                        src={user.profileImageURL}
                        alt={user.displayName}
                      />
                    ) : (
                      <>
                        <svg
                          className="h-8 w-8 rounded-full text-main-base"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </>
                    )}
                    <span className="text-xs font-medium text-header-dark ml-1 hidden xl:block xl:max-w-[160px] truncate">
                      {user ? <>{user.displayName}</> : <>Loading...</>}
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-header-base py-1 shadow-lg ring-1 ring-main-base focus:outline-none divide-y divide-main-base"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabIndex="-1"
                    >
                      <div className="py-1" role="none">
                        <Link
                          to="/profile"
                          className="cursor-pointer block px-4 py-1 text-sm text-header-light hover:text-header-hover-light"
                          role="menuitem"
                          tabIndex="-1"
                          id="user-menu-item-2"
                        >
                          Profile
                        </Link>
                        <a
                          className="cursor-pointer block px-4 py-1 text-sm text-header-light hover:text-header-hover-light"
                          role="menuitem"
                          tabIndex="-1"
                          onClick={handleLogoutModal}
                          id="user-menu-item-2"
                        >
                          Logout
                        </a>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
