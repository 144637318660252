import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const setUserData = (user) => {
    setUser(user);
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const login = (token) => {
    // Save token to local storage
    localStorage.setItem("token", JSON.stringify(token));
    setAuth(token);
  };

  const logout = () => {
    // Remove token from local storage
    localStorage.removeItem("token");
    setAuth(null);
    setUserData(null);
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        logoutModalOpen,
        setLogoutModalOpen,
        sidebarOpen,
        setSidebarOpen,
        setUserData,
        login,
        logout,
        getToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
