import React from "react";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import Swal from 'sweetalert2'
import { Switch } from "@headlessui/react";
import { Controller } from "react-hook-form";
import useAuth from "../hooks/useAuth";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function StandingOrders(props) {
    const { currentTrip, getstandingOrderDays, setValue, watch, control, register, errors } = props;
    const { user, getToken } = useAuth();

    const onDisableStandingOrder = (v) => {
        if (!v && currentTrip) {
            Swal.fire({
                title: "Alert?",
                text: 'Are you sure you want to inactive standing orders? This action will reset the recurring date to today and remove all the future existing trips.',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#80ac7f",
                cancelButtonColor: "#ABB2B9",
                confirmButtonText: "Yes"
            }).then((result) => {
                if (result.isConfirmed) {
                    setValue("isRecurring", v);
                    setValue("isStandingOrder", v);
                    const today = moment().tz(`${user.company.timeZone}`, true).startOf("day");
                    const recurringEndDate = watch('recurringEndDate');
                    if (recurringEndDate) {
                        const previousDate = moment(recurringEndDate).tz(`${user.company.timeZone}`, true).startOf('day');
                        if (previousDate.isAfter(today)) {
                            const d = new Date(today.format("YYYY-MM-DD HH:mm:ss"));
                            setValue("recurringEndDate", d);
                        }
                    } else {
                        const d = new Date(today.format("YYYY-MM-DD HH:mm:ss"));
                        setValue("recurringEndDate", d);
                    }
                } else {
                    setValue("isRecurring", !v);
                    setValue("isStandingOrder", !v);
                }
            });
        } else {
            setValue("isRecurring", v);
            setValue("isStandingOrder", v);
        }
    }



    // On Change Standing Order
    const handleCheckboxChange = (dayIndex, checked, driver) => {
        getstandingOrderDays[dayIndex].checked = checked;
        getstandingOrderDays[dayIndex].driver = driver;
        const recurringDays = getstandingOrderDays.filter((day) => day.checked);
        setValue("recurringDays", recurringDays);
        if (!checked) {
        }
    };

    return (
        <>
            <div className="pt-8">
                <div>
                    <h3 className="text-lg font-medium leading-6 text-main-dark">
                        Standing Order
                    </h3>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                        <div className="h-full flex items-cente">
                            <Controller
                                name="isStandingOrder"
                                control={control}
                                // rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <Switch
                                        checked={value}
                                        onChange={(v) => {
                                            onDisableStandingOrder(v);
                                        }}
                                        className={classNames(
                                            value ? "bg-main-primary" : "bg-main-base",
                                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-main-primary focus:ring-offset-2"
                                        )}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                            className={classNames(
                                                value ? "translate-x-5" : "translate-x-0",
                                                "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-container-base shadow ring-0 transition duration-200 ease-in-out"
                                            )}
                                        >
                                            <span
                                                className={classNames(
                                                    value
                                                        ? "opacity-0 duration-100 ease-out"
                                                        : "opacity-100 duration-200 ease-in",
                                                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                                )}
                                                aria-hidden="true"
                                            >
                                                <svg
                                                    className="h-3 w-3 text-main-light"
                                                    fill="none"
                                                    viewBox="0 0 12 12"
                                                >
                                                    <path
                                                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                            <span
                                                className={classNames(
                                                    value
                                                        ? "opacity-100 duration-200 ease-in"
                                                        : "opacity-0 duration-100 ease-out",
                                                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                                )}
                                                aria-hidden="true"
                                            >
                                                <svg
                                                    className="h-3 w-3 text-main-primary"
                                                    fill="currentColor"
                                                    viewBox="0 0 12 12"
                                                >
                                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                </svg>
                                            </span>
                                        </span>
                                    </Switch>
                                )}
                            />
                        </div>
                    </div>

                    {watch("isStandingOrder") && (
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="recurringEndDate"
                                className="block text-sm font-medium text-main-secondary"
                            >
                                Recurring End Date{" "}
                                <span className="text-main-primary/90 font-normal">
                                    (Not Mandatory)
                                </span>
                            </label>
                            <div className="mt-1">
                                <Controller
                                    control={control}
                                    rules={{ required: false }}
                                    name="recurringEndDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            placeholderText="Select date"
                                            // minDate={new Date()}
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    )}
                                />
                            </div>
                            {errors.recurringEndDate && (
                                <p className="mt-2 text-xs text-main-danger">
                                    Recurring End Date
                                </p>
                            )}
                        </div>
                    )}
                    {(currentTrip && currentTrip.status === 'standing') && (
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="recurringstatus"
                                className="block text-sm font-medium text-main-secondary"
                            >
                                Recurring Status
                            </label>
                            <div className="mt-1">
                                <select
                                    id="recurringstatus"
                                    {...register("isRecurring")}
                                    onChange={(evt) => {
                                        onDisableStandingOrder(JSON.parse(evt.target.value))
                                    }}
                                >
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                </select>
                                {(watch("isStandingOrder") === false) && (
                                    <p className="text-main-danger text-xs">
                                        If you deactivate this standing order, the system will delete all linked trips starting from today and into the future.
                                    </p>
                                )}
                            </div>
                        </div>

                    )}

                    {watch("isStandingOrder") && (
                        <div className="sm:col-span-6 text-center">
                            <p className="text-main-danger text-xs ">
                                UPON CREATING NEW STANDING ORDER. THE SYSTEM WILL
                                CREATE TRIPS FOR ONE WHOLE WEEK ON THE GIVEN DAYS.
                                AFTER THAT THE RECURRING RIDES WILL BE CREATED 7
                                DAYS BEFORE THE PICKUP DATE AND TIME.
                            </p>
                        </div>
                    )}


                    {watch("isStandingOrder") && (
                        <div className="sm:col-span-6 border-2 border-main-base border-dashed p-4 bg-main-base-100 rounded-lg flex flex-wrap gap-4">
                            {getstandingOrderDays.map((item, index) => (
                                <div
                                    key={item.label}
                                    className="flex justify-between items-center"
                                >
                                    <div className="flex items-center gap-4">
                                        <div>
                                            <input
                                                type="checkbox"
                                                name={item.label}
                                                onChange={(e) =>
                                                    handleCheckboxChange(
                                                        index,
                                                        e.target.checked,
                                                        item.driver
                                                    )
                                                }
                                                id={item.label}
                                                defaultChecked={item.checked}
                                            />
                                        </div>
                                        <label
                                            htmlFor={item.label}
                                            className="mt-1 inline text-sm font-medium text-main-secondary"
                                        >
                                            {item.label}
                                        </label>
                                    </div>
                                    {/* <div>
              <select
                name=""
                className="min-w-[180px] sm:min-w-[400px] disabled:bg-main-base"
                onChange={(e) =>
                  handleCheckboxChange(
                    index,
                    item.checked,
                    e.target.value
                  )
                }
                defaultValue={item.driver}
                disabled={!item.checked ? true : false}
              >
                <option value="">-- Select Driver --</option>
                {drivers.map((item, index) => (
                  <option key={item._id} value={item._id}>
                    {item.displayName}
                  </option>
                ))}
              </select>
            </div> */}
                                </div>
                            ))}
                        </div>
                    )}

                    {watch("isStandingOrder") &&
                        !watch("recurringDays").length && (
                            <div className="sm:col-span-6 text-left">
                                <p className="text-main-danger text-xs ">
                                    Please select days to proceed the standing order
                                </p>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
}
