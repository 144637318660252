import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {
  const {
    auth,
    user,
    logoutModalOpen,
    setLogoutModalOpen,
    sidebarOpen,
    setSidebarOpen,
    setUserData,
    login,
    logout,
    getToken,
  } = useContext(AuthContext);

  return {
    auth,
    user,
    logoutModalOpen,
    setLogoutModalOpen,
    sidebarOpen,
    setSidebarOpen,
    setUserData,
    login,
    logout,
    getToken,
  };
};

export default useAuth;
