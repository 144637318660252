import React from "react";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { titlecase } from "../utils/common";
import Moment from "react-moment";

export default function MemberSearch(props) {
    const { watch, setValue, reset, vehicles, addons } = props;
    
    const memberName = watch("priorityClient.displayName");
    const { user, getToken } = useAuth();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          // Check if 'user' and 'memberName' meet the conditions
          if (user && memberName && memberName.length >= 3) {
            checkMemberHistory();
          }
        }, 1000);
      
        // Cleanup function to clear the timeout when the component unmounts or when 'memberName' changes
        return () => clearTimeout(timeoutId);
    }, [memberName, user]);
    

    const [filteredMemberList, setFilteredMemberList] = useState([]);
    const [members, setMembers] = useState([]);

    const checkMemberHistory = async () => {
        let { priorityClient } = watch();
    
        try {
          const payload = {
            displayName: priorityClient.displayName,
            cooperate: user.cooperate ? user.cooperate._id : user._id,
          };
    
          const response = await fetch(
            `${process.env.REACT_APP_BASEURL}assigns/corporate?displayName=${payload.displayName}&cooperate=${payload.cooperate}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${JSON.parse(getToken())}`,
              }
              // ,
              // body: JSON.stringify(payload),
            }
          );
    
          const jsonData = await response.json();
          if (jsonData) {
            setMembers(jsonData);
            setFilteredMemberList(jsonData);
          }
        } catch (error) {
          console.log("Error:", error);
        }
      };
    
      const setMemberData = (item) => {
        let data = { ...item };
        delete data.scheduleTime;
        delete data.appointmentTime;
        delete data.recurringEndDate;
        delete data._id;
        data.recurringDays = [];
        const userAddons = addons && addons.addonServices && addons.addonServices.length ? addons.addonServices : undefined;
        if (userAddons) {
          if (data.addonServices && data.addonServices.length) {
            const filteredAddons = data.addonServices.filter(ad => {
              return userAddons.find(addon => addon._id === ad.addonId) ? true : false;
            });
            data.addonServices = filteredAddons;
          }
        } else {
          data.addonServices = [];
        }
        reset(data);
    
        let defaultVehicle = vehicles[0].name;
        let isContainDefault = false;
    
        const myVehicles =
          user.cooperate && user.cooperate.vehicles
            ? user.cooperate.vehicles
            : user.vehicles;
    
        if (myVehicles && myVehicles.length) {
          const fVehicles = vehicles.filter((v) => myVehicles.includes(v.name));
    
          if (fVehicles.length) {
            if (myVehicles.includes(data.tripRequirement)) {
              isContainDefault = true;
            }
    
            defaultVehicle = fVehicles[0].name;
          } else {
            defaultVehicle = "";
          }
        } else {
          defaultVehicle = "";
        }
    
        if (isContainDefault) defaultVehicle = data.tripRequirement;
        setValue("tripRequirement", defaultVehicle);
      };
    
    
      const sortTripsByScheduleTime = (trips) => {
        const sortedTrips = trips.sort((a,b) => {
          return new Date(a.scheduleTime) - new Date(b.scheduleTime)
        });
        return sortedTrips;
      }
    
    
    const filterMember = (keyword) => {
        if (keyword !== "") {
          const results = members.filter((member) => {
            return member.trips.some((item) => {
              return (
                item.priorityClient.displayName
                  .toLowerCase()
                  .includes(keyword.toLowerCase()) ||
                (item.payor &&
                  item.payor.toLowerCase().includes(keyword.toLowerCase()))
              );
            });
          });
          setFilteredMemberList(results);
        } else {
          setFilteredMemberList(members);
        }
      };

    return (
        <>
            <div className="w-full lg:w-72 xl:w-96 flex-shrink-0 card px-0 py-0 flex flex-col p-4 h-96 lg:h-screen overflow-hidden">
                <div className="h-full flex flex-col">
                    <div className="px-4 py-2">
                        <div className="relative">
                            <div
                                aria-hidden="true"
                                className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="mr-3 h-4 w-4 text-main-light"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        clipRule="evenodd"
                                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                        fillRule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                className="pl-9 ng-untouched ng-pristine ng-valid"
                                id="search"
                                name="search"
                                placeholder="Search Payor/Client Name"
                                onChange={(e) => filterMember(e.target.value)}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end px-4 text-xs font-medium text-main-secondary">
                        Member Records ({filteredMemberList.length})
                    </div>
                    <ul className=" px-4 py-2 flex h-full flex-col flex-1 overflow-y-auto gap-4">
                        {filteredMemberList.map((itemMain, mIndex) => (
                            <li
                                className="p-2 border-2 rounded-lg border-main-base border-dashed ng-star-inserted"
                                key={itemMain._id}

                            >
                                <div className="flex flex-col gap-1 px-2 py-1">
                                    <div className="text-xs text-main-primary font-bold">
                                        M{mIndex + 1}-{itemMain._id}
                                    </div>
                                    <div className="flex flex-wrap gap-1">
                                        <div className="flex text-xs text-main-light items-center">
                                            Total
                                            <span className="px-1 flex-shrink-0 rounded grid place-content-center ml-1 text-white bg-main-primary border-main-base">
                                                {itemMain.total}
                                            </span>
                                        </div>
                                        <div className="flex text-xs text-main-light items-center">
                                            Optum
                                            <span className="px-1 flex-shrink-0 rounded grid place-content-center ml-1 text-white bg-main-primary border-main-base">
                                                {itemMain.optum}
                                            </span>
                                        </div>
                                        <div className="flex text-xs text-main-light items-center">
                                            Medicaid
                                            <span className="px-1 flex-shrink-0 rounded grid place-content-center ml-1 text-white bg-main-primary border-main-base">
                                                {itemMain.medicaid}
                                            </span>
                                        </div>
                                        <div className="flex text-xs text-main-light items-center">
                                            Private Pay
                                            <span className="px-1 flex-shrink-0 rounded grid place-content-center ml-1 text-white bg-main-primary border-main-base">
                                                {itemMain.privatePay}
                                            </span>
                                        </div>
                                        <div className="flex text-xs text-main-light items-center">
                                            Bill Facility
                                            <span className="px-1 flex-shrink-0 rounded grid place-content-center ml-1 text-white bg-main-primary border-main-base">
                                                {itemMain.billFacility}
                                            </span>
                                        </div>
                                        <div className="flex text-xs text-main-light items-center">
                                            Other
                                            <span className="px-1 flex-shrink-0 rounded grid place-content-center ml-1 text-white bg-main-primary border-main-base">
                                                {itemMain.other}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {sortTripsByScheduleTime(itemMain.trips).map((item, index) => (
                                    <div key={index} className="relative cursor-pointer mt-2" onClick={() => setMemberData(item)}>
                                        <div className="mix-blend-multiply rounded-md ring-1 ring-main-base p-2 flex flex-col text-xs text-main-secondary bg-main-base-50 shadow">
                                            <div className="flex flex-wrap justify-between items-center gap-2">
                                                <div className="text-main-primary font-bold">
                                                    T{index + 1}-{item.priorityClient
                                                        ? item.priorityClient.displayName
                                                        : "N/A"}
                                                </div>
                                                {item.payor && (
                                                    <span className="inline-flex items-center rounded-md bg-gray-700 px-1.5 py-0.5 text-[9px] font-medium text-white ">
                                                        {titlecase(item.payor)}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="flex flex-col mt-2">
                                                <div className="relative pb-4">
                                                    <span
                                                        aria-hidden="true"
                                                        className="absolute inset-y-0 mt-1 left-2 ml-px h-full w-0.5 border border-dashed border-main-light"
                                                    ></span>
                                                    <div className="relative flex items-start gap-x-2">
                                                        <div className="h-5 w-5 bg-container-base rounded-full overflow-hidden grid place-content-center flex-shrink-0">
                                                            <svg
                                                                className="w-4 h-4 text-main-success"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                ></path>
                                                                <path
                                                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <div className="text-main-secondary">
                                                                {item.jobOriginAddress
                                                                    ? item.jobOriginAddress
                                                                    : "N/A"}
                                                            </div>
                                                            <div>
                                                                <small className="text-main-secondary">
                                                                    {item.scheduleTime && user ? (
                                                                        <Moment
                                                                            format="MM/DD/YYYY HHmm"
                                                                            tz={user.company.timeZone}
                                                                        >
                                                                            {item.scheduleTime}
                                                                        </Moment>
                                                                    ) : (
                                                                        "N/A"
                                                                    )}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative">
                                                    <div className="relative flex items-start gap-x-2">
                                                        <div className="h-5 w-5 bg-container-base rounded-full overflow-hidden grid place-content-center flex-shrink-0">
                                                            <svg
                                                                className="w-4 h-4 text-main-danger"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                ></path>
                                                                <path
                                                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <div className="text-main-secondary">
                                                                {item.jobDestinationAddress
                                                                    ? item.jobDestinationAddress
                                                                    : "N/A"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>))}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );

}