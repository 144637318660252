import io from 'socket.io-client';

export default function socket(token) {
    return io(process.env.REACT_APP_SERVERURL, {
        force: true,
        query: {
            "auth_token": JSON.parse(token)
            // "auth_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiNjQ2NWUzNDA2NjEzNWY2MjhkM2EwZTkzIiwiYWNjb3VudFN0YXR1cyI6ImFjdGl2ZSIsInJvbGVzIjoiY29vcGVyYXRlIiwiaWF0IjoxNjk1MjQzNTUxfQ.PGzYyvxQWOD_JYJlGviY1JkSuZqrn4UyfUjjA7LOfiU"
        }
    });
}


