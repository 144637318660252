import React from "react";
import CoverImage from "../assets/images/new-year/bg-cover.jpg"
import YearImage from "../assets/images/new-year/2024.png"
import Logo from "../assets/logo.png"
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";

export default function Tokenization() {
    const navigate = useNavigate();
    const { auth, getToken, user, setUserData, login, logout } = useAuth();
    let params = useParams();
    let token = params.token;

    useEffect(() => {
        if (!token) return;
        const timeoutId = setTimeout(() => {
            login(token);
            navigate("/");
        }, 5000);
        return () => {
            clearTimeout(timeoutId);
        };

    }, [token]);

    return (
        <>
            <div class="relative">
                <div class="absolute inset-0">
                    <img class="h-full w-full object-cover object-left object-top" src={CoverImage} alt="logo"/>
                    <div class="absolute inset-0 flex justify-center">
                        <img class="object-scale-down h-24 mt-4 w-auto" src={YearImage} alt="" />
                    </div>
                    <div class="bg-is-primary/50 absolute inset-0 mix-blend-multiply"></div>
                </div>
                <div class="relative grid min-h-screen place-content-center py-32">

                    <div class="w-[350px] md:w-[700px] flex flex-col bg-white p-8 sm:p-16 md:p-12 items-center">
                        <div class="pb-12">
                            <img class="h-20 w-auto" src={Logo} alt="logo" />
                        </div>
                        <div class=" mx-auto flex flex-col md:flex-row items-center justify-center gap-4 rounded-lg ">
                            <div class="grid h-24 w-24 flex-shrink-0 place-content-center rounded-full bg-[#293462] ring ring-white ring-offset-2">
                                <div class="grid grid-cols-2">
                                    <img src="https://nemtpanel.com/assets/images/nemt-logo-panel.png" class="h-8 w-8 object-scale-down" alt="" />
                                    <img src="https://nemtpanel.com/assets/images/nemt-logo-panel.png" class="h-8 w-8 object-scale-down" alt="" />
                                </div>
                                <div class="flex justify-center">
                                    <img src="https://nemtpanel.com/assets/images/nemt-logo-panel.png" class="h-8 w-8 object-scale-down" alt="" />
                                </div>
                            </div>
                            <div class="flex flex-col items-center md:flex-row">
                                <div class="h-24 w-px border-r-2 border-dashed border-primary md:h-px md:w-24 md:border-b-2 md:border-r-0"></div>
                                <div class="mt-0.5 md:ml-0.5 md:mt-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 rotate-90 text-primary md:rotate-0">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                    </svg>
                                </div>
                            </div>
                            <div class="grid h-24 w-24 flex-shrink-0 place-content-center rounded-full bg-white p-6 ring-1 ring-black">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-full w-full text-[#293462]" viewBox="0 0 32 32">
                                    <path fill="currentColor" d="m11 23.18l-2-2.001l-1.411 1.41L11 26l6-6l-1.41-1.41L11 23.18zM28 30h-4v-2h4V16h-4V8a4.005 4.005 0 0 0-4-4V2a6.007 6.007 0 0 1 6 6v6h2a2.002 2.002 0 0 1 2 2v12a2.002 2.002 0 0 1-2 2z" />
                                    <path fill="currentColor" d="M20 14h-2V8A6 6 0 0 0 6 8v6H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V16a2 2 0 0 0-2-2ZM8 8a4 4 0 0 1 8 0v6H8Zm12 20H4V16h16Z" />
                                </svg>
                            </div>
                            <div class="flex flex-col items-center md:flex-row">
                                <div class="h-24 w-px border-r-2 border-dashed border-primary md:h-px md:w-24 md:border-b-2 md:border-r-0"></div>
                                <div class="mt-0.5 md:ml-0.5 md:mt-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 rotate-90 text-primary md:rotate-0">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                    </svg>
                                </div>
                            </div>
                            <div class="grid h-24 w-24 flex-shrink-0 place-content-center rounded-full bg-[#293462] p-6 ring ring-white ring-offset-2">
                                <img src="https://nemtpanel.com/assets/images/nemt-logo-panel.png" class="h-full w-full object-scale-down" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
