
import { useEffect, useState, Fragment } from "react";
import Moment from "react-moment";
import moment from "moment-timezone";
import { getstandingOrderDaysAsObject, exportToCsv } from "../utils/common";
import { useNavigate, Navigate, useLocation, Outlet } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import useAuth from "../hooks/useAuth";
import { Menu, Transition } from '@headlessui/react'
import { ChevronUpDownIcon, PencilIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import StandingOrderDetailsModal from '../StandingOrders/StandingOrderDetailsModal';
import { toTitleCase } from "../utils/common";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function StandingOrderList(props) {
  let navigate = useNavigate();
  const [standingOrders, setStandingOrders] = useState([]);
  const [data, setData] = useState([]);
  const { getToken, user } = useAuth();
  const [standingOrderDays, setStandingOrderDays] = useState(null);
  const [status, setStatus] = useState(true);
  const [standingDays, setStandingDays] = useState(null);
  const [selectedTrip, setSelectedTrip] = useState(null);

  const getStandingOrders = async () => {
    const token = JSON.parse(getToken());
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}assigns/getmanifesttrips?status=standing&isRecurring=${status}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          }
        }
      );

      const data = await response.json();
      setStandingOrders(data);
      setData(data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  function handleStatusChange(idx, isChecked) {
    console.log(idx, isChecked);
    let sDays = [...standingDays];
    sDays[idx].isChecked = isChecked;
    setStandingDays(sDays); 
    console.log(standingDays);
    filter();
  }

  function filter() {
    let days = [];
    standingDays.forEach(d => {
      if (d.isChecked) {
        days.push(d.value);
      }
    });

    if (days.length === 0) {
      setStandingOrders(data);
      return;
    }

    setStandingOrders(data.filter(s => s.recurringDays.some(r => days.includes(r.day))));
  }

  const handleSearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = data.filter((item) => {
        return item.priorityClient.displayName
          .toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setStandingOrders(results);
    } else {
      setStandingOrders(data);
    }
  };


  useEffect(() => {
    setStandingDays([
      { label: 'Sun', value: 0, isChecked: true },
      { label: 'Mon', value: 1, isChecked: true },
      { label: 'Tue', value: 2, isChecked: true },
      { label: 'Wed', value: 3, isChecked: true },
      { label: 'Thu', value: 4, isChecked: true },
      { label: 'Fri', value: 5, isChecked: true },
      { label: 'Sat', value: 6, isChecked: true },
    ])
    setStandingOrderDays(getstandingOrderDaysAsObject())
  }, []);

  useEffect(() => {
    getStandingOrders();
  }, [user,status]);

  function handleTripEdit(id){
    navigate(`/trip/${id}/edit`)
  }

  function viewStandingOrderDetails(trip) {
    setSelectedTrip(trip);
  }

  const handleModalClose = () => {
    setSelectedTrip(null);
  }

  function downloadReport() {
    const table = [
      [
        "Trip ID",
        "Member",
        "PU Time",
        "Apt Time",
        "Pickup",
        "Dropoff",
        "LOS",
        "Type",
        "Days",
        "Status",
        "End Date"
      ]
    ];

    for (let i = 0; i < standingOrders.length; i++) {
      const trip = standingOrders[i];

      let type = '';
      if(trip.jobType === 'cooperate') {
        if (trip.cooperate) {
          type = user ? user.displayName : "None"
        } else {
          type = "None";
        }
      }

      let days = '';
      days = trip.recurringDays.map(r => {
        return standingOrderDays[r.day]
      });
      days = days && days.length ? days.toString() : '';

      const standingOrder = [
        trip.tripId,
        trip.priorityClient ? trip.priorityClient.displayName : "N/A",
        moment(trip.scheduleTime).format("HH:mm"),
        moment(trip.appointmentTime).format("HH:mm"),
        trip.jobOriginAddress,
        trip.jobDestinationAddress,
        trip.tripRequirement,
        type,
        days,
        trip.isRecurring ? "Active": "Inactive",
        trip.recurringEndDate ? moment(trip.recurringEndDate).format("MM/DD/YYYY") : "N/A"
      ]
      table.push(standingOrder);
    }
    exportToCsv(`standing-orders-${ moment().format("MM-DD-YYYY")}.csv`, table);
  }

  return (
    <>
      <div className="gap-4 p-2 bg-[#f6f7fb] sticky left-0 top-0 z-20 w-full">
        <div className="flex flex-wrap gap-2 items-center justify-between">
          <div className="flex gap-2 items-center">
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" aria-hidden="true">
                <svg className="mr-3 h-4 w-4 text-main-light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd" />
                </svg>
              </div>
              <input onChange={handleSearch} type="text" name="search" id="search" className="pl-9" placeholder="Search" />
            </div>
            <div>
              <Menu as="div" className="relative w-24">
                <Menu.Button type="button"
                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                  <span className="block truncate">Days</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-4 w-4" />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm divide-y divide-[#d7d7d7]"
                    tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                    {standingDays && standingDays.map((sD, index) => (
                      <li key={sD.value} className="relative cursor-default select-none p-1 flex items-center gap-3" id="listbox-option-0"
                        role="option">
                        <span>
                          <input id={sD.value} type="checkbox"

                            onChange={(e) =>
                              handleStatusChange(
                                index,
                                e.target.checked
                              )
                            }
                            defaultChecked={sD.isChecked}
                          />
                        </span>
                        <label for="{{sD.value}}" className="text-sm block truncate text-[#333333]">{sD.label}</label>
                      </li>
                    ))}
                  </Menu.Items >
                </Transition>
              </Menu>
            </div>
            <div>
              <select id="status" name="status" onChange={(event) => setStatus(event.target.value)}>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          </div>
          <div className="flex-1 flex justify-center">
            <span className="text-main-light text-sm whitespace-nowrap">
              Showing {standingOrders && standingOrders.length ? standingOrders.length : '0'} entries
            </span>
          </div>
          <div>
            <button className="btn-primary" onClick={() => downloadReport()}>Export CSV</button>
          </div>
        </div>

      </div>
      <div className="px-6 inline-block min-w-full h-full overflow-y-scroll">
        <div className="flow-root">
          <div className="">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Trip ID</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Member</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      PU Time </th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      APT Time</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Pick-Up</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Drop-off</th>

                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      LOS</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Type</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Days</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Payor</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Status</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                      Period</th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                    </th>
                    <th scope="col"
                      className="sticky top-0 z-10 border-b border-[#d4d4d4] bg-[#f6f7fb] py-2 px-[5px] text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter">
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {/* *ngFor="let assign of dataList | filter : search" [ngClass]="{'bg-red-100' : (assign.diffFromToday <= 0),
              'bg-orange-100' : (assign.diffFromToday > 0 && assign.diffFromToday <=7)}" */}
                  {standingOrders && standingOrders.map((assign, index) => (
                    // onClick={() => handleTripEdit(assign._id)}
                    <tr key={assign.tripId} className="hover:bg-main-base-100 cursor-pointer">
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[120px] max-w-[2] truncate">
                          {assign.tripId}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[160px] max-w-[160px] truncate">
                          {assign.priorityClient ? (assign.priorityClient.displayName) : 'N/A'}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[140px] max-w-[140px] truncate">
                          {/* <!-- Tue, June 13 --> */}
                          {/* {{ assign.scheduleTime | date : "HH:mm" }} */}
                          {assign.scheduleTime && user ? (
                            <Moment
                              format="HH:mm"
                              tz={user.company.timeZone}
                            >
                              {assign.scheduleTime}
                            </Moment>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[100px] max-w-[100px] truncate">
                          {/* <!-- 07:10 --> */}
                          {/* {{ assign.appointmentTime | date : "HH:mm" }} */}
                          {assign.appointmentTime && user ? (
                            <Moment
                              format="HH:mm"
                              tz={user.company.timeZone}
                            >
                              {assign.appointmentTime}
                            </Moment>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[260px] max-w-[260px] truncate">
                          {assign.jobOriginAddress}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[260px] max-w-[260px] truncate">
                          {assign.jobDestinationAddress}
                        </div>
                      </td>

                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[100px] max-w-[100px] truncate">
                          {assign.tripRequirement}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[100px] max-w-[100px] truncate">
                          {assign.jobType !== 'cooperate'
                            ? assign.companyType === ""
                              ? "Cab Dispatch"
                              : assign.companyType
                            : ''
                          }

                          {assign.jobType === 'cooperate'
                            ? assign.cooperate === ""
                              ? assign.cooperate.displayName
                              : 'None'
                            : ''
                          }
                          {/* <ng-container *ngIf="assign.jobType !== 'cooperate'">
                    {{assign.companyType === "" ? "Cab Dispatch" : brokerList(assign.companyType)}}
                  </ng-container> */}
                          {/* <ng-container *ngIf="assign.jobType === 'cooperate'">
                    <span *ngIf="assign.cooperate && getCorporateByID(assign.cooperate) as corporate"> {{
                      corporate?.displayName || "None"}}</span>
                    <span *ngIf="!assign?.cooperate">None</span>
                  </ng-container> */}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="flex gap-1">
                          {assign.recurringDays.map((recurringDay, index) => (
                            <div  key={recurringDay.day} className="">
                              <span
                                className="inline-flex items-center rounded-md bg-badge-primary px-1.5 py-0.5 text-[9px] font-medium text-white">
                                {standingOrderDays[recurringDay.day]}
                              </span>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[100px] max-w-[100px] truncate">
                          {assign.payor && <span className="inline-flex items-center rounded-md bg-gray-700 px-2 py-1 text-xs font-medium text-white ring-inset">
                              {toTitleCase(assign.payor)}</span>
                          }
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[100px] max-w-[100px] truncate">
                          {assign.isRecurring ? "Active" : "In Active"}
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <div className="min-w-[180px] max-w-[180px]">
                          {assign.scheduleTime && user
                            ? <Moment format="MM/DD/YYYY" tz={user.company.timeZone}>
                              {assign.scheduleTime}
                            </Moment>
                            : "N/A"
                          } to&nbsp;
                          {assign.recurringEndDate && user
                            ? <Moment format="MM/DD/YYYY" tz={user.company.timeZone}>
                              {assign.recurringEndDate}
                            </Moment>
                            : "N/A"
                          }
                        </div>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                        <button onClick={() => handleTripEdit(assign._id)}
                          className="px-2 text-main-primary hover:text-main-primary-hover hover:cursor-pointer duration-500 ease-linear">
                         <PencilSquareIcon className="w-5 h-5" />
                        </button>
                      </td>
                      <td className="p-[5px] text-sm border-b border-[hsl(0,0%,95%)] text-[#333333]">
                        <button onClick={() => viewStandingOrderDetails(assign)}
                          className="px-2 text-main-primary hover:text-main-primary-hover hover:cursor-pointer duration-500 ease-linear">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                            <path fillRule="evenodd"
                              d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                              clipRule="evenodd" />
                          </svg>
                        </button>
                      </td>
                      {/* <td className="p-[5px] text-sm border-b border-[#f2f2f2] text-[#333333]">
                      
                        <button
                          className="px-2 text-main-primary hover:text-main-primary-hover hover:cursor-pointer duration-500 ease-linear">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" className="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                          </svg>
                        </button>
                      </td> */}
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedTrip && <StandingOrderDetailsModal trip={selectedTrip} handleClose={handleModalClose} />} 
    </>
  );
}
