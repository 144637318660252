import {
  ChatBubbleBottomCenterIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Tab } from "@headlessui/react";
import { useEffect, useState, Fragment } from "react";
import Moment from "react-moment";
import moment from "moment-timezone";
import "moment-timezone";
import TripNotes from "../components/TripNotes";
import { Triangle } from "react-loader-spinner";
import TripDetails from "../components/TripDetails";
import { motion } from "framer-motion";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import useAuth from "../hooks/useAuth";
import { titlecase } from "../utils/common";

export default function History(props) {
  const [isNoteShow, setIsNoteShow] = useState(false);
  const [isTripDetailsShow, setIsTripDetailsShow] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState({});
  const [trips, setTrips] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [todayDate, setTodayDate] = useState(new Date());
  const { getToken, user } = useAuth();
  const token = getToken();
  const cleanedToken = token.replace(/^"(.*)"$/, "$1");
  // console.log("token in hisoty ::::::", cleanedToken);

  // console.log("todayDate", todayDate);

  const handleTripNoteModal = (tripDetails) => {
    setSelectedTrip(tripDetails);
    setIsNoteShow(true);
  };

  const handleTripDetailsModal = (tripDetails) => {
    setSelectedTrip(tripDetails);
    setIsTripDetailsShow(true);
  };

  function CallApi(date) {
    // return;
    // console.log("date in function", date);
    let d = moment(date).utc().startOf("day").hour(4);
    // console.log("Moment Date ::: ", d.toDate());
    setTodayDate(d.toDate());
    let payload = {
      corporate: "5e1562898e78de1465f80fb0",
      isShowAllTrips: false,
      jobType: "cooperate",
      scheduleTime: d.toISOString(),
    };

    // console.log("payload history", payload);

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}cooperatedispatches?cooperate=${payload.corporate}&isShowAllTrips=${payload.isShowAllTrips}&jobType=${payload.jobType}&scheduleTime=${payload.scheduleTime}&status=history`,
          {
            headers: {
              // Authorization: `Bearer ${jwtToken}`,
              Authorization: `JWT ${cleanedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        const jsonData = await response.json();
        setTrips(jsonData);
        setFilteredList(jsonData);
        // console.log(jsonData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }

  useEffect(() => {
    // setTodayDate("2023-05-05T00:00:00.000Z");
    CallApi();
  }, []);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = trips.filter((item) => {
        return item.priorityClient.displayName
          .toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setFilteredList(results);
    } else {
      setFilteredList(trips);
    }
  };

  return (
    <>
      {filteredList ? (
        <>
          <Tab.Group
            as="div"
            className="px-4 sm:px-6 lg:px-8 py-4 overflow-y-auto"
          >
            <div className="flex flex-wrap justify-between items-center gap-4">
              <div className="relative">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <svg
                    className="mr-3 h-4 w-4 text-main-light"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="pl-9"
                  placeholder="Search Client Name"
                  onChange={filter}
                />
              </div>
              <div className="hidden flex-1 sm:flex justify-center">
                <span className="text-main-light text-xs whitespace-nowrap">
                  Showing {filteredList.length} entries
                </span>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <div>
                  <DatePicker
                    selected={todayDate}
                    onChange={(date) => CallApi(date)}
                  />
                </div>
                <Tab.List
                  as="div"
                  className="flex items-center border rounded-md border-main-base shadow p-1 gap-1"
                >
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={clsx(
                          selected
                            ? "bg-main-primary text-white"
                            : "bg-white text-main-secondary",
                          "p-1 rounded focus:outline-none"
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                          />
                        </svg>
                      </button>
                    )}
                  </Tab>
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={clsx(
                          selected
                            ? "bg-main-primary text-white"
                            : "bg-white text-main-secondary",
                          "p-1 rounded focus:outline-none"
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                          />
                        </svg>
                      </button>
                    )}
                  </Tab>
                </Tab.List>
              </div>
            </div>
            <Tab.Panels as="div" className="mt-4">
              <Tab.Panel as="div" className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-main-base md:rounded-lg">
                      <table className="min-w-full divide-y divide-main-base">
                        <thead className="bg-main-base-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-main-dark sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-xs font-semibold text-main-dark"
                            >
                              Contact No.
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-xs font-semibold text-main-dark"
                            >
                              PU Address
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-xs font-semibold text-main-dark"
                            >
                              DO Address
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-xs font-semibold text-main-dark"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-main-base bg-white">
                          {filteredList.map((item, index) => (
                            <motion.tr
                              initial={{ opacity: 0, y: -10 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.5, delay: index * 0.2 }}
                              key={index}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                                <div>
                                  <div className="font-medium text-main-dark">
                                    {item.priorityClient
                                      ? item.priorityClient.displayName
                                      : "N/A"}
                                  </div>
                                  <div className="text-main-light">
                                    {item.tripId ? item.tripId : "N/A"}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-xs text-main-light">
                                <div>
                                  <div className="font-medium text-main-dark">
                                    {item.priorityClient
                                      ? item.priorityClient.contactNumber
                                      : "N/A"}
                                  </div>
                                  <div className="text-main-light">
                                    {" "}
                                    {item.priorityClient
                                      ? item.priorityClient.cellPhone
                                      : "N/A"}
                                  </div>
                                </div>
                              </td>
                              <td className=" px-3 py-4 text-xs text-main-light">
                                <div>
                                  <div className="w-72 font-medium text-main-dark">
                                    {item.jobOriginAddress
                                      ? item.jobOriginAddress
                                      : "N/A"}
                                  </div>
                                  <div className="text-main-light">
                                    {item.scheduleTime && user ? (
                                      <Moment
                                        format="MM/DD/YYYY HHmm"
                                        tz={user.company.timeZone}
                                      >
                                        {item.scheduleTime}
                                      </Moment>
                                    ) : (
                                      "N/A"
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className=" px-3 py-4 text-xs text-main-light">
                                <div>
                                  <div className="w-72 font-medium text-main-dark">
                                    {item.jobDestinationAddress
                                      ? item.jobDestinationAddress
                                      : "N/A"}
                                  </div>
                                  <div className="text-main-light">
                                    {" "}
                                    {item.appointmentTime && user ? (
                                      <Moment
                                        format="MM/DD/YYYY HHmm"
                                        tz={user.company.timeZone}
                                      >
                                        {item.appointmentTime}
                                      </Moment>
                                    ) : (
                                      "N/A"
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-xs text-main-light">
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  {item.status ? titlecase(item.status) : "N/A"}
                                </span>
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
                                <div className="flex justify-end divide-x items-center divide-main-base">
                                  <button
                                    onClick={() => handleTripNoteModal(item)}
                                    className="px-2 text-main-primary hover:text-main-primary-hover duration-500 ease-linear"
                                  >
                                    <ChatBubbleBottomCenterIcon className="h-5 w-5" />
                                  </button>
                                  <button
                                    onClick={() => handleTripDetailsModal(item)}
                                    className="px-2 text-main-primary hover:text-main-primary-hover duration-500 ease-linear"
                                  >
                                    <EyeIcon className="h-5 w-5" />
                                  </button>
                                </div>
                              </td>
                            </motion.tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel
                as="div"
                className="mt-4 grid md:grid-cols-2 xl:grid-cols-3 gap-6"
              >
                {filteredList.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                    className="relative"
                  >
                    <div className="mix-blend-multiply rounded-md ring-1 ring-main-base p-2 flex flex-col text-xs text-main-secondary shadow-lg">
                      <div className="flex items-start justify-between flex-wrap">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-1">
                            <div className="text-main-primary font-bold">
                              {item.priorityClient
                                ? item.priorityClient.displayName
                                : "N/A"}
                            </div>
                          </div>
                          <div className="text-main-light">
                            {item.tripId ? item.tripId : "N/A"}
                          </div>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="inline-flex items-center rounded-md bg-main-primary px-1.5 py-0.5 text-[9px] font-medium text-white">
                            {item.status ? item.status : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col mt-2">
                        <div className="relative">
                          <div className="relative flex items-start gap-x-2">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4 text-main-danger"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                />
                              </svg>
                            </div>
                            <div>
                              <div className="text-main-secondary">
                                {item.jobOriginAddress
                                  ? item.jobOriginAddress
                                  : "N/A"}
                              </div>
                              <div className="text-main-light">
                                {item.scheduleTime && user ? (
                                  <Moment
                                    format="MM/DD/YYYY HHmm"
                                    tz={user.company.timeZone}
                                  >
                                    {item.scheduleTime}
                                  </Moment>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="relative">
                          <div className="relative flex items-start gap-x-2">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-4 h-4 text-main-success"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                />
                              </svg>
                            </div>
                            <div>
                              <div className="text-main-secondary">
                                {item.jobDestinationAddress
                                  ? item.jobDestinationAddress
                                  : "N/A"}
                              </div>
                              <div className="text-main-light">
                                {item.appointmentTime && user ? (
                                  <Moment
                                    format="MM/DD/YYYY HHmm"
                                    tz={user.company.timeZone}
                                  >
                                    {item.appointmentTime}
                                  </Moment>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 flex flex-col gap-2">
                        <div className="flex flex-wrap items-center gap-y-2 gap-x-6">
                          <div className="flex items-center gap-x-1.5">
                            <div>
                              <svg
                                className="h-4 w-4 text-main-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01l-1.97 5.67c-.07.21-.11.43-.11.66v7.16c0 .83.67 1.5 1.5 1.5S6 20.33 6 19.5V19h12v.5c0 .82.67 1.5 1.5 1.5c.82 0 1.5-.67 1.5-1.5v-7.16c0-.22-.04-.45-.11-.66l-1.97-5.67zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zM5 11l1.27-3.82c.14-.4.52-.68.95-.68h9.56c.43 0 .81.28.95.68L19 11H5z"
                                />
                              </svg>
                            </div>
                            <div className="text-main-light">
                              {item.jobCarType ? item.jobCarType : "N/A"}
                            </div>
                          </div>
                          <div className="flex items-center gap-x-1.5">
                            <div>
                              <svg
                                className="h-4 w-4 text-main-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1024 1024"
                              >
                                <path
                                  fill="currentColor"
                                  d="M511.984 36.128C230.016 36.128.639 265.536.639 547.504c0 177.152 89.68 339.185 239.903 433.408c14.944 9.472 34.688 4.88 44.097-10.096s4.88-34.72-10.096-44.095c-54.096-33.952-99.04-78.048-133.424-128.88l33.552-19.376c15.311-8.848 20.56-28.4 11.712-43.711c-8.88-15.344-28.464-20.56-43.712-11.712l-33.6 19.391c-24.4-50.511-39.297-105.792-43.281-163.424h35.616c17.68 0 32-14.32 32-32s-14.32-32-32-32H65.95c4.24-58.687 19.776-114.304 44.56-164.592l32.16 18.56a31.745 31.745 0 0 0 15.97 4.288c11.055 0 21.807-5.744 27.743-16c8.847-15.312 3.6-34.88-11.712-43.713l-31.84-18.368c32.112-46.832 72.864-87.296 119.984-119.023l18.016 31.2c5.935 10.288 16.687 16 27.743 16a31.75 31.75 0 0 0 15.969-4.288c15.311-8.848 20.56-28.4 11.712-43.712l-17.953-31.072c49.329-23.792 103.68-38.656 160.976-42.816v39.872c0 17.68 14.32 32 32 32s32-14.32 32-32v-40c58.592 4.08 114.128 19.391 164.384 43.95l-17.36 30.049c-8.848 15.311-3.6 34.88 11.712 43.712a31.745 31.745 0 0 0 15.969 4.288c11.055 0 21.807-5.712 27.743-16l17.28-29.936a451.19 451.19 0 0 1 118.88 118.816l-29.968 17.312c-15.311 8.847-20.56 28.4-11.711 43.71c5.935 10.289 16.687 16 27.743 16c5.44 0 10.944-1.375 15.969-4.287l30.127-17.392C938.638 401.839 954 457.39 958.094 516H922.96c-17.68 0-32 14.32-32 32s14.32 32 32 32h35.12c-4.048 56.88-18.592 111.439-42.496 161.312l-31.68-18.288c-15.28-8.848-34.912-3.568-43.712 11.713c-8.848 15.311-3.6 34.88 11.712 43.712L883.68 796.8c-35.103 52.24-81.44 97.393-137.359 131.824c-15.055 9.28-19.712 29.008-10.464 44.032c6.065 9.808 16.529 15.216 27.28 15.216a31.896 31.896 0 0 0 16.753-4.752c152.464-93.904 243.472-256.784 243.472-435.632c0-281.952-229.408-511.36-511.376-511.36zm236.127 411.6c15.296-8.848 20.544-28.398 11.712-43.71c-8.832-15.296-28.416-20.544-43.712-11.696L542.287 492.674c-9.28-5.248-19.856-8.496-31.28-8.496c-35.28 0-63.84 28.591-63.84 63.807c0 35.248 28.576 63.84 63.84 63.84c35.28 0 63.84-28.592 63.84-63.84c0-.064-.016-.144-.016-.209z"
                                />
                              </svg>
                            </div>
                            <div className="text-main-light">
                              {" "}
                              {item.milage ? item.milage : "N/A"}
                            </div>
                          </div>
                          <div className="flex items-center gap-x-1.5">
                            <div>
                              <svg
                                className="h-4 w-4 text-main-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1c0 9.39 7.61 17 17 17c.55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1c-1.24 0-2.45-.2-3.57-.57a.84.84 0 0 0-.31-.05c-.26 0-.51.1-.71.29l-2.2 2.2a15.149 15.149 0 0 1-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 0 1 8.5 4c0-.55-.45-1-1-1z"
                                />
                              </svg>
                            </div>
                            <div className="text-main-light">
                              {" "}
                              {item.priorityClient
                                ? item.priorityClient.contactNumber
                                : "N/A"}
                            </div>
                          </div>
                          <div className="flex items-center gap-x-1.5">
                            <div>
                              <svg
                                className="h-4 w-4 text-main-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                                />
                              </svg>
                            </div>
                            <div className="text-main-light">
                              {item.priorityClient
                                ? item.priorityClient.cellPhone
                                : "N/A"}
                            </div>
                          </div>
                          <div
                            className="flex items-center gap-x-1.5 cursor-pointer"
                            onClick={() => handleTripNoteModal(item)}
                          >
                            <div>
                              <svg
                                className="h-4 w-4 text-main-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <g fill="currentColor">
                                  <path d="M6 6a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm1 3a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H7Zm-1 5a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M2 4a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V4Zm3-1h14a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z"
                                    clipRule="evenodd"
                                  />
                                </g>
                              </svg>
                            </div>
                            <div>
                              <div className="text-main-primary font-medium">
                                {" "}
                                Notes{" "}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-x-1.5">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-4 w-4 text-main-primary"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"
                                />
                              </svg>
                            </div>
                            <div className="text-main-light">
                              {item.state ? item.state : "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 border-t border-main-base pt-2">
                        <div className="flex flex-wrap justify-start gap-2">
                          <button
                            onClick={() => handleTripDetailsModal(item)}
                            className="btn-white py-1 px-2 text-xs"
                          >
                            Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <TripNotes
            isNoteShow={isNoteShow}
            setIsNoteShow={setIsNoteShow}
            selectedTrip={selectedTrip}
          />
          <TripDetails
            isTripDetailsShow={isTripDetailsShow}
            setIsTripDetailsShow={setIsTripDetailsShow}
            selectedTrip={selectedTrip}
          />
        </>
      ) : (
        <div className="fixed inset-0 grid place-content-center bg-white">
          <Triangle
            height="100"
            width="100"
            color="#749671"
            ariaLabel="loading"
          />
        </div>
      )}
    </>
  );
}
