import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Moment from "react-moment";
import "moment-timezone";
import useAuth from "../hooks/useAuth";
import pickupPin from "../assets/map/bucket_pin2.png";
import dropoffPin from "../assets/map/bucket_pin3.png";
import { PAYOR, toTitleCase } from "../utils/common";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import LocationPin from "../assets/images/location-pin.png";
import { titlecase } from "../utils/common";

export default function TripDetails(props) {
  const { getToken, user } = useAuth();
  const token = getToken();
  const cleanedToken = token.replace(/^"(.*)"$/, "$1");
  let [trip, setTrip] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [map, setMap] = useState();
  let [routePath, setRoutePath] = useState([]);

  async function callApi() {
    const url = `${process.env.REACT_APP_BASEURL}assigns/${props.selectedTrip._id}`;
    try {
      const response = await fetch(url, {
        headers: {
          // Authorization: `Bearer ${jwtToken}`,
          Authorization: `JWT ${cleanedToken}`,
          "Content-Type": "application/json",
        },
      });

      const jsonData = await response.json();
      trip = jsonData;
      setTrip(trip);

      const pointA = {
        lat: trip.jobOriginLatitude,
        lng: trip.jobOriginLongitude,
      };
      const pointB = {
        lat: trip.jobDestinationLatitude,
        lng: trip.jobDestinationLongitude,
      };
   
      routePath = [pointA, pointB];
      setRoutePath(routePath)
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(pointA);
      bounds.extend(pointB);
      map.fitBounds(bounds);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (map && user && props.selectedTrip._id) {
      callApi();
    }
  }, [props.selectedTrip._id,map]);

  useEffect(() => {
    if (user) {
      if (user.cooperate && user.cooperate.showStatus) {
        setShowStatus(true);
      } else if (user.showStatus) {
        setShowStatus(true);
      }
    }
  }, [user]);

  const onLoad = (map) => {
    setMap(map);
  };

  const breakString = (str) => {
    if (!str) return "";
    return str.replace(/\n/g, "<br>");
  }

  return (
    <Transition.Root show={props.isTripDetailsShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={props.setIsTripDetailsShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-main-light bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-main-dark">
                          Details
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-main-light hover:text-main-light focus:outline-none focus:ring-2 focus:ring-main-primary focus:ring-offset-2"
                            onClick={() => props.setIsTripDetailsShow(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 overflow-y-auto p-6 grid lg:grid-cols-2 gap-8">
                      <div className="space-y-8">
                        <div className="flex flex-col text-xs">
                          <div className="relative pb-2">
                            <span
                              className="absolute inset-y-0 mt-1 left-3.5 -ml-px h-full w-0.5 border border-dashed border-main-light"
                              aria-hidden="true"
                            ></span>
                            <div className="relative flex items-start gap-x-2">
                              <div className="p-1 bg-container-base rounded-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 text-main-success"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                  />
                                </svg>
                              </div>
                              <div className="mt-1">
                                <div className="text-main-dark font-medium">
                                  {trip.jobOriginAddress
                                    ? trip.jobOriginAddress
                                    : "N/A"}
                                </div>
                                <div className="text-main-light">
                                  {trip.scheduleTime && user ? (
                                    <Moment
                                      format="MM/DD/YYYY HHmm"
                                      tz={user.company.timeZone}
                                    >
                                      {trip.scheduleTime}
                                    </Moment>
                                  ) : (
                                    "N/A"
                                  )}
                                </div>
                                <div className="text-main-light flex flex-wrap gap-px ng-star-inserted">
                                {trip.originAddressAdditional && trip.originAddressAdditional.room && <dl className="flex items-center gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                      Room:
                                    </dt>
                                    <dl>
                                      {trip.originAddressAdditional.room}
                                    </dl>
                                  </dl>}
                                  {trip.originAddressAdditional && trip.originAddressAdditional.floor &&  <dl className="flex items-center ml-1 gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                      Floor:
                                    </dt>
                                    <dl>
                                        {trip.originAddressAdditional.floor}
                                    </dl>
                                  </dl>}
                                
                                  {trip.originAddressAdditional && trip.originAddressAdditional.aptOrSuite && <dl className="flex items-center ml-1 gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                      APT/Suite:
                                    </dt>
                                    <dl>
                                      {trip.originAddressAdditional
                                          .aptOrSuite}
                                    </dl>
                                  </dl>}
                                 
                                  {trip.originAddressAdditional && trip.originAddressAdditional.place && <dl className="flex items-center ml-1 gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                    Home / Facility / Physician Name:
                                    </dt>
                                    <dl>
                                      {trip.originAddressAdditional.place }
                                    </dl>
                                  </dl>}
                                  {trip.originAddressAdditional && trip.originAddressAdditional.phone && <dl className="flex items-center ml-1 gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                      Phone Number:
                                    </dt>
                                    <dl>
                                      {trip.originAddressAdditional.phone}
                                    </dl>
                                  </dl>}

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="relative">
                            <div className="relative flex items-start gap-x-2">
                              <div className="p-1 bg-container-base rounded-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 text-main-danger"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                  />
                                </svg>
                              </div>
                              <div className="mt-1">
                                <div className="text-main-dark font-medium">
                                  {trip.jobDestinationAddress
                                    ? trip.jobDestinationAddress
                                    : "N/A"}
                                </div>
                                <div className="text-main-light">
                                  {trip.appointmentTime && user ? (
                                    <Moment
                                      format="MM/DD/YYYY HHmm"
                                      tz={user.company.timeZone}
                                    >
                                      {trip.appointmentTime}
                                    </Moment>
                                  ) : (
                                    "N/A"
                                  )}
                                </div>
                                <div className="text-main-light flex flex-wrap gap-px ng-star-inserted">
                               
                                  {trip.destinationAddressAdditional && trip.destinationAddressAdditional.room && <dl className="flex items-center gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                      Room:
                                    </dt>
                                    <dl>
                                      {trip.destinationAddressAdditional.room}
                                    </dl>
                                  </dl>}
                                  {trip.destinationAddressAdditional && trip.destinationAddressAdditional.floor &&  <dl className="flex items-center ml-1 gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                      Floor:
                                    </dt>
                                    <dl>
                                        {trip.destinationAddressAdditional.floor}
                                    </dl>
                                  </dl>}
                                  {trip.destinationAddressAdditional && trip.destinationAddressAdditional.aptOrSuite && <dl className="flex items-center ml-1 gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                    APT/Suite:
                                    </dt>
                                    <dl>
                                      {trip.destinationAddressAdditional
                                          .aptOrSuite}
                                    </dl>
                                  </dl>}
                                  {trip.destinationAddressAdditional && trip.destinationAddressAdditional.place && <dl className="flex items-center ml-1 gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                    Home / Facility / Physician Name:
                                    </dt>
                                    <dl>
                                      {trip.destinationAddressAdditional.place }
                                    </dl>
                                  </dl>}
                                  {trip.destinationAddressAdditional && trip.destinationAddressAdditional.phone && <dl className="flex items-center ml-1 gap-1 ng-star-inserted">
                                    <dt className="font-medium text-main-dark">
                                      Phone Number:
                                    </dt>
                                    <dl>
                                      {trip.destinationAddressAdditional.phone}
                                    </dl>
                                  </dl>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-medium text-sm text-main-dark">
                            Trip Route
                          </h3>
                          <dl className="mt-2 divide-y divide-main-base border border-main-base">
                            <div className="py-3">
                              <div className="aspect-[3/2]">
                                <GoogleMap
                                  zoom={5}
                                  onLoad={onLoad}
                                  mapContainerClassName="h-full w-full"
                                >
                                  {/* <Marker
                                      icon={{
                                        url: LocationPin,
                                        scaledSize: {
                                          width: 40,
                                          height: 40
                                        }
                                      }
                                        }
                                      position={{
                                        lat: props.selectedTrip
                                          .jobOriginLatitude,
                                        lng: props.selectedTrip
                                          .jobOriginLongitude,
                                      }}
                                    /> */}
                                  {trip && <Marker
                                     label={{color: 'white', fontSize: '11px',
                                     fontWeight: 'bold', text: 'P'}}
                                    icon={{
                                      url: pickupPin,
                                      scaledSize: {
                                        width: 38,
                                        height: 38
                                      },
                                      labelOrigin: { x: 19, y: 14 }
                                      }}
                                    position={{
                                      lat: trip.jobOriginLatitude,
                                      lng: trip.jobOriginLongitude,
                                    }}
                                  />}
                                  {trip && <Marker
                                     label={{color: 'white', fontSize: '11px',
                                     fontWeight: 'bold', text: 'D'}}
                                    icon={{
                                      url: dropoffPin,
                                      scaledSize: {
                                        width: 38,
                                        height: 38
                                      },
                                      labelOrigin: { x: 19, y: 14 }
                                      }}
                                    position={{
                                      lat: trip.jobDestinationLatitude,
                                      lng: trip.jobDestinationLongitude,
                                    }}
                                  />}
                                  {routePath && routePath.length && <Polyline
                                    path={routePath}
                                    options={{
                                      strokeColor: "#3D5F3D",
                                      strokeOpacity: 1,
                                      strokeWeight: 4,
                                    }}
                                  />}
                                </GoogleMap>
                              </div>
                            </div>
                          </dl>
                        </div>
                        {/* <div>
                          <h3 className="font-medium text-sm text-main-dark">
                            Text Live Link
                          </h3>
                          <div className="py-3">
                            <div className="text-main-light text-xs font-medium">
                              Member's Phone Number
                            </div>
                            <div className="flex space-x-2">
                              <input type="text"></input><button className="btn-primary" onC>Send</button>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="space-y-8">
                        <div>
                          <div className="flex items-center justify-between gap-2">
                            <h3 className="font-medium text-sm text-main-dark">
                              Trip Information
                            </h3>
                            <div>
                              {trip.attachment && (
                                <a
                                  href={trip.attachment}
                                  download
                                  className="cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="inline-flex h-6 w-6"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 2v14H5V5h14m-1.1 8.5c-.3-.5-1-.7-2.2-.7c-.4 0-.8 0-1.2.1c-.3-.2-.6-.4-.8-.6c-.6-.5-1.2-1.4-1.5-2.5v-.1c.3-1.3.6-2.8 0-3.5c-.3-.1-.5-.2-.7-.2h-.2c-.4 0-.7.4-.8.7c-.4 1.3-.1 2 .2 3.2c-.2.9-.6 1.8-1 2.8c-.4.7-.7 1.3-1 1.8c-.4.2-.7.3-.9.5c-1.1.7-1.7 1.5-1.8 2v.5l.5.3c.2.2.3.2.5.2c.8 0 1.7-.9 2.9-3h.1c1-.3 2.2-.5 3.9-.7c1 .5 2.2.7 2.9.7c.4 0 .7-.1.9-.3c.2-.2.3-.4.3-.6c0-.3 0-.5-.1-.6M6.8 17.3c0-.4.5-1 1.2-1.6c.1-.1.3-.2.5-.3c-.7 1.1-1.3 1.8-1.7 1.9m4.5-10.6s0-.1.1-.1h.1c.2.2.2.5.1 1.1v.2c-.1.2-.1.5-.2.8c-.3-.9-.3-1.6-.1-2m-1.2 7.6H10c.1-.3.3-.6.5-1c.4-.8.8-1.6 1-2.3c.4.9.9 1.6 1.5 2.1c.1.1.3.2.4.3c-.9.1-2.1.4-3.3.9m7.2-.1h-.2c-.4 0-1.1-.2-1.8-.5c.1-.1.2-.1.2-.1c1.4 0 1.7.2 1.8.3l.1.1c0 .2 0 .2-.1.2z"
                                      fill="#888888"
                                    />
                                  </svg>
                                </a>
                              )}
                            </div>
                          </div>
                          <dl className="mt-2 divide-y divide-main-base border-t border-b border-main-base">
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">ID</dt>
                              <dd className="text-main-dark">
                                {trip.tripId ? trip.tripId : "N/A"}
                              </dd>
                            </div>

                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Created By</dt>
                              <dd className="text-main-dark">
                                {trip.createdBy ? trip.createdBy : "N/A"}
                              </dd>
                            </div>

                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Payor</dt>
                              <dd className="text-main-dark">
                                {trip.payor ? toTitleCase(trip.payor) : "N/A"}
                              </dd>
                            </div>
                            {/* <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Status</dt>
                              <dd className="text-main-dark">
                                <span className="capitalize inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  {trip.status
                                    ? trip.status
                                    : "N/A"}
                                </span>
                              </dd>
                            </div> */}
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Miles</dt>
                              <dd className="text-main-dark">
                                {" "}
                                {trip.milage ? trip.milage : "N/A"}
                              </dd>
                            </div>

                            {/* <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">
                                Customer Special Rate
                              </dt>
                              <dd className="text-main-dark">
                                {" "}
                                $
                                {trip.customerSpecialRate
                                  ? trip.customerSpecialRate
                                  : "0"}
                              </dd>
                            </div> */}
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">LOS</dt>
                              <dd className="text-main-dark">
                                {trip.tripRequirement
                                  ? trip.tripRequirement
                                  : "N/A"}
                              </dd>
                            </div>
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Insurance ID</dt>
                              <dd className="text-main-dark">
                                {trip.priorityClient
                                  ? trip.priorityClient.clientId
                                  : "N/A"}
                              </dd>
                            </div>
                            {showStatus && (
                              <div className="flex justify-between py-3 text-xs font-medium">
                                <dt className="text-main-light">Status</dt>
                                <dd className="text-main-dark space-x-2">
                                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    {trip.status
                                      ? titlecase(trip.status)
                                      : "N/A"}
                                  </span>
                                  {trip.viewStatus &&
                                    trip.viewStatus === "returned" &&
                                    trip.status === "cancelled" && (
                                      <span className="inline-flex items-center rounded-md bg-red-300 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-red-600/20">
                                        {titlecase(trip.viewStatus)}
                                      </span>
                                    )}
                                </dd>
                              </div>
                            )}
                            {trip.tripReason &&
                            (<div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Purpose</dt>
                              <dd className="text-main-dark">
                                {trip.tripReason}
                              </dd>
                            </div>)}
                            {/* <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">
                                No. of Passenger
                              </dt>
                              <dd className="text-main-dark">
                                {" "}
                                {trip.jobPassengerNumber
                                  ? trip.jobPassengerNumber
                                  : "N/A"}
                              </dd>
                            </div> */}
                            {/* <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Provider</dt>
                              <dd className="text-main-dark">
                                {" "}
                                {trip.company
                                  ? trip.company.displayName
                                  : "N/A"}
                              </dd>
                            </div> */}
                          </dl>
                        </div>
                        <div>
                          <h3 className="font-medium text-sm text-main-dark">
                            Passenger Information
                          </h3>
                          <dl className="mt-2 divide-y divide-main-base border-t border-b border-main-base">
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Name</dt>
                              <dd className="text-main-dark">
                                {" "}
                                {trip.priorityClient
                                  ? trip.priorityClient.displayName
                                  : "N/A"}
                              </dd>
                            </div>
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Phone Number</dt>
                              <dd className="text-main-dark">
                                {" "}
                                {trip.priorityClient
                                  ? trip.priorityClient.contactNumber
                                  : "N/A"}
                              </dd>
                            </div>
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Email</dt>
                              <dd className="text-main-dark">
                                {" "}
                                {trip.priorityClient
                                  ? trip.priorityClient.email
                                  : "N/A"}
                              </dd>
                            </div>
                          </dl>
                        </div>
                        <div>
                          <h3 className="font-medium text-sm text-main-dark">
                            Notes
                          </h3>
                          <dl className="mt-2 divide-y divide-main-base border-t border-b border-main-base">
                            <div className="flex flex-wrap justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">
                                Company/Driver Notes
                              </dt>
                              {trip.companyNote && 
                              <dd className="text-main-dark"
                              dangerouslySetInnerHTML={{ __html: breakString(trip.companyNote) }}>
                            </dd>}
                              
                            </div>
                            {/* <div className="flex flex-wrap justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">
                                Dispacher Notes
                              </dt>
                              <dd className="text-main-dark">
                                {" "}
                                {trip.dispatchNoteTwo
                                  ? trip.dispatchNoteTwo
                                  : "N/A"}
                              </dd>
                            </div> */}
                            {trip.changeReason && (
                              <div className="flex flex-wrap justify-between py-3 text-xs font-medium">
                                <dt className="text-main-light">
                                  Cancel Reason
                                </dt>

                                <dd className="text-main-dark">
                                  {" "}
                                  {trip.changeReason
                                    ? trip.changeReason
                                    : "N/A"}
                                </dd>
                              </div>
                            )}
                          </dl>
                        </div>
                        {/* <div>
                          <h3 className="font-medium text-sm text-main-dark">
                            Driver Information
                          </h3>
                          <dl className="mt-2 divide-y divide-main-base border-t border-b border-main-base">
                            <div className="flex justify-between items-center py-3 text-xs font-medium">
                              <dt className="text-main-light">Driver Image</dt>
                              <dd className="h-8 w-8 rounded-full overflow-hidden">
                                <img
                                  src="https://angelridenetwork.s3.amazonaws.com/users/1658867114665-newProfilePicture"
                                  alt=""
                                  className="h-full w-full object-cover"
                                />
                              </dd>
                            </div>
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Driver Name</dt>
                              <dd className="text-main-dark">
                                Hector Javier JR Ortiz
                              </dd>
                            </div>
                            <div className="flex justify-between py-3 text-xs font-medium">
                              <dt className="text-main-light">Phone Number</dt>
                              <dd className="text-main-dark">(760) 000-5007</dd>
                            </div>
                          </dl>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
