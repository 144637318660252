import React from "react";
import { useState, useEffect } from "react";
import { Controller} from "react-hook-form";
import GoogleAutoComplete from "../components/GoogleAutoComplete";
import useAuth from "../hooks/useAuth";

export default function AddressBoxComponent(props) {
    const {lableName, addressFieldName, addressAdditionals, restrictRadius, radius, control, setValue, setAutoState, register, errors } = props;
    const { user, getToken } = useAuth();

    const onKeyUpAddress = (address, val) => {
        if (!val) {
          setValue(address, null);
        }
      };

    return (
        <>
            {<div className="sm:col-span-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 border-2 border-main-base border-dashed p-4 rounded-lg items-end">
                <div className="sm:col-span-6">
                    <label
                        htmlFor={addressFieldName}
                        className="block text-sm font-medium text-main-secondary"
                    >
                        <span
                            className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                        >
                            {lableName}
                        </span>
                        <span className="ml-1 text-main-primary/90 font-normal">
                            (Required)
                        </span>
                    </label>
                    <div className="mt-1">
                        {user && (
                            <Controller
                                name={addressFieldName}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <GoogleAutoComplete
                                        defaultValue={value}
                                        restrictRadius={restrictRadius}
                                        radius={radius}
                                        onPlaceSelected={(place) => {
                                            onChange(place);
                                            setAutoState(place);
                                        }}
                                        onKeyUp={(e) =>
                                            onKeyUpAddress(
                                                addressFieldName,
                                                e.target.value
                                            )
                                        }
                                    />
                                )}
                            />
                        )}
                    </div>
                    {errors[{addressFieldName}] && (
                        <p className="mt-2 text-xs text-main-danger">
                            Enter the {lableName}
                        </p>
                    )}
                </div>
                <div className="sm:col-span-2">
                    <label className="block text-sm font-medium text-main-secondary">
                        Floor
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            {...register(`${addressAdditionals}.floor`, {
                                required: false,
                            })}
                        />
                    </div>
                </div>
                <div className="sm:col-span-2">
                    <label className="block text-sm font-medium text-main-secondary">
                        Room
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            {...register(`${addressAdditionals}.room`, {
                                required: false,
                            })}
                        />
                    </div>
                </div>
                <div className="sm:col-span-2">
                    <label className="block text-sm font-medium text-main-secondary">
                        APT/SUITE
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            {...register(`${addressAdditionals}.aptOrSuite`, {
                                required: false,
                            })}
                        />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label className="block text-sm font-medium text-main-secondary">
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                            {" "}
                            Home / Facility / Physician Name
                        </span>
                        <span className="ml-1 text-main-primary/90 font-normal">
                            (Required)
                        </span>
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            {...register(`${addressAdditionals}.place`, {
                                required: true,
                            })}
                        />
                        {errors[addressAdditionals]?.place && (
                            <p className="mt-2 text-xs text-main-danger">
                                Enter the Home/Facility/Physician Name
                            </p>
                        )}
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label className="block text-sm font-medium text-main-secondary">
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                            {" "}
                            Phone Number
                        </span>
                        <span className="ml-1 text-main-primary/90 font-normal">
                            (Required)
                        </span>
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            {...register(`${addressAdditionals}.phone`, {
                                required: true,
                            })}
                        />
                        {errors[addressAdditionals]?.phone && (
                            <p className="mt-2 text-xs text-main-danger">
                                Enter the Phone Number
                            </p>
                        )}
                    </div>
                </div>
            </div>}
        </>);

} 