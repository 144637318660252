const UserRoles = {
    ADMIN: 'ccooperate',
    CALLCENTER_REP: 'crcallcenter',
    TRANSPORT_COORDINATOR: 'cdispatcher'
}

export function getUserRoles() {
    return UserRoles;
}

export function getSubMenus(user) {
  const roles = user.roles;
    let subMenus = [
       {
         name: "All",
         value: "all",
         roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR]
       },
       {
         name: "Unassigned",
         value: "unassigned",
         roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR]
       },
       {
         name: "Assigned",
         value: "hanging",
         roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR]
       },
       {
         name: "In Progress",
         value: "inprogress",
         roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR]
       },
       {
         name: "Cancelled",
         value: "cancelled",
         roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR]
       },
      //  {
      //    name: "Archived",
      //    value: "archived",
      //    roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR]
      //  },
       {
         name: "Completed",
         value: "completed",
         roles: [UserRoles.ADMIN, UserRoles.CALLCENTER_REP, UserRoles.TRANSPORT_COORDINATOR]
       }
     ];

     let roleBasedMenus= [];
    //  subMenus = subMenus.filter(s=> (user && user.showStatus) || s.value === 'all');
     if (!(user && user.showStatus)) {
      roleBasedMenus.push(subMenus[0]);
     } else {
      subMenus.forEach(m => {
        if (roles.includes('cooperate')) {
          roleBasedMenus.push(m);
        } else {
          if (isRoleExist(m.roles, roles)) {
            roleBasedMenus.push(m);
          }
        }
       });
     }
     
     return roleBasedMenus;
}

function isRoleExist(menuRoles, userRoles) {
  return menuRoles.some(m => userRoles.includes(m));
}