import React, { useState, useRef } from 'react';
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";

function FileUpload({ pdfFile, setPdfFile }) {

    const { user, getToken } = useAuth();
    let [selectedFile, setSelectedFile] = useState(null);
    let [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);


    useEffect(() => { 
        setSelectedFile(pdfFile);
    },[pdfFile]);


    const handleFileChange = (event) => {
        selectedFile = event.target.files[0];
        setSelectedFile(selectedFile);
        handleUpload();
    };

    const handleCustomButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleUpload = () => {

        if (!selectedFile) return;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile, selectedFile.name);
        fetch(`${process.env.REACT_APP_BASEURL}protips`, {
            method: 'PUT',
            headers: {
       
                Authorization: `JWT ${JSON.parse(getToken())}`,
              },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                setIsLoading(false);
                pdfFile = data.location;
                setPdfFile(data.location);
                selectedFile = data.location;
                setSelectedFile(data.location);
                
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error uploading file:', error);
                // Handle error
            });

    };

    const getFileURL = (fileRef) => {
        return (typeof fileRef === "string" ? fileRef : URL.createObjectURL(fileRef));
    }

    return (
        <div>
            <div className="inline-flex border-2 border-dashed border-main-base p-4 rounded-lg justify-start gap-4 relative">
                <input accept="application/pdf" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} type="file" />
                <button className=" btn-danger" onClick={handleCustomButtonClick} type="button">
                    {isLoading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>}
                    Attach PDF
                </button>
                {selectedFile && <div>
                    <a className="btn-primary ng-star-inserted" target="_blank" rel="noreferrer" href={getFileURL(selectedFile)}><svg className="h-5 w-5" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" strokeLinecap="round" strokeLinejoin="round"></path></svg></a>
                    <div className="absolute -top-3.5 -right-3.5 ng-star-inserted">
                        <button className="rounded-full bg-main-dark p-1 text-white focus:outline-none focus:ring-2 focus:ring-main-dark focus:ring-offset-2" type="button"
                            onClick={() => {
                                fileInputRef.current.value = "";
                                setPdfFile(null);
                                setSelectedFile(null);
                            }}>
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                        </button>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default FileUpload;
