import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DummyLogoImage from "../assets/dummy-logo.png";
import useAuth from "../hooks/useAuth";
import {getRoles} from "../utils/common";

export default function Profile() {
  const [error, setError] = useState(null);
  const { user, getToken } = useAuth();
  const token = getToken();
  const cleanedToken = token.replace(/^"(.*)"$/, "$1");
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (user) {
      setImageUrl(user.profileImageURL);
    }
  }, [user]);

  const validateInput = (value) => {
    const trimmedValue = value.trim(); // Trim leading and trailing spaces

    if (trimmedValue === "") {
      return "This field is required";
    }

    return true; // Validation passed
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    console.log();
    const imageUrl = URL.createObjectURL(file);
    setImageFile(file);
    setImageUrl(imageUrl);

    let ImagePayload = {
      newProfilePicture: file,
    };

    const formData = new FormData();
    Object.keys(ImagePayload).forEach((key) => {
      formData.append(`${key}`, ImagePayload[key]);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}users/picture`,
        {
          method: "POST",
          headers: {
            Authorization: "JWT " + cleanedToken,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();
      toast.info(responseData.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setError("An error occurred. Please try again later.");
    }
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);

    let payload = {
      newPassword: data.newPassword,
      verifyPassword: data.confirmPassword,
      currentPassword: data.currentPassword,
    };

    console.log("payload", payload);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}users/password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + cleanedToken,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();
      toast.info(responseData.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setError(null);
    } catch (error) {
      console.error(error);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setError("An error occurred. Please try again later.");
    }
    reset();
  };

  const validateConfirmPassword = (value) => {
    const newPassword = watch("newPassword");

    if (value !== newPassword) {
      return "New password and Confirm password do not match";
    }

    return true; // Validation passed
  };

  return (
    <>
      <div className="overflow-y-auto">
        <div className="divide-y divide-main-dark/10">
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
            <div>
              <h2 className="text-base font-semibold leading-7 text-main-dark">
                Company Information
              </h2>
              {/* <p className="mt-1 text-sm leading-6 text-main-light">
                Use a permanent address where you can receive mail.
              </p> */}
            </div>

            <div className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="flex flex-col col-span-full">
                  <div className="flex items-center gap-x-8">
                    <img
                      src={imageUrl || DummyLogoImage}
                      alt=""
                      className="h-24 w-24 flex-none rounded-lg object-scale-down border border-main-base"
                    />
                    <div>
                      <button
                        type="button"
                        className="btn-primary"
                        variant="outline"
                      >
                        <label htmlFor="file-upload">
                          <span>Upload</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            accept="image/*"
                            // {...register("image", { required: false })}
                            onChange={handleImageUpload}
                            className="sr-only"
                          />
                        </label>
                      </button>
                    </div>
                  </div>
                  {/* {!imageFile && errors.image && (
                    <p className="mt-2 text-xs text-red-600">Logo Required</p>
                  )} */}
                </div>
                <dl className="col-span-full space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <div className="pt-6 sm:flex justify-between">
                    <dt className="font-medium text-main-dark sm:w-64 sm:flex-none sm:pr-6">
                      Company Name
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-main-dark">
                        {user ? user.displayName : "Loading..."}
                      </div>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-main-dark sm:w-64 sm:flex-none sm:pr-6">
                      Email address
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-main-dark">
                        {" "}
                        {user ? user.email : "Loading..."}
                      </div>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-main-dark sm:w-64 sm:flex-none sm:pr-6">
                      Contact Number
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-main-dark">
                        {" "}
                        {user ? user.contactNumber : "Loading..."}
                      </div>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-main-dark sm:w-64 sm:flex-none sm:pr-6">
                      Provider Name
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-main-dark">
                        {" "}
                        {user ? user.company.displayName : "Loading..."}
                      </div>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-main-dark sm:w-64 sm:flex-none sm:pr-6">
                      Address
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-main-dark">
                        {user ? user.address : "Loading..."}
                      </div>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-main-dark sm:w-64 sm:flex-none sm:pr-6">
                      Roles
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-main-dark">
                        {user ? getRoles(user.roles).toString() : 'Loading...' }
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
          >
            <div>
              <h2 className="text-base font-semibold leading-7 text-main-dark">
                Change password
              </h2>
              <p className="mt-1 text-sm leading-6 text-main-light">
                Update your password associated with your account.
              </p>
            </div>

            <div className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <label
                    htmlFor="currentPassword"
                    className="block text-sm font-medium text-main-secondary"
                  >
                    Current password
                  </label>
                  <div className="mt-1">
                    <input
                      id="currentPassword"
                      name="currentPassword"
                      type="password"
                      {...register("currentPassword", {
                        required: "This field is required",
                        validate: validateInput,
                        minLength: {
                          value: 6,
                          message: "Minimum length is 6 characters",
                        },
                        maxLength: {
                          value: 16,
                          message: "Maximum length is 16 characters",
                        },
                      })}
                    />
                  </div>
                  {errors.currentPassword && (
                    <p className="mt-2 text-xs text-main-danger">
                      {errors.currentPassword.message}
                    </p>
                  )}
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="newPassword"
                    className="block text-sm font-medium text-main-secondary"
                  >
                    New password
                  </label>
                  <div className="mt-1">
                    <input
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      {...register("newPassword", {
                        required: "This field is required",
                        validate: validateInput,
                        minLength: {
                          value: 6,
                          message: "Minimum length is 6 characters",
                        },
                        maxLength: {
                          value: 16,
                          message: "Maximum length is 16 characters",
                        },
                      })}
                    />
                  </div>
                  {errors.newPassword && (
                    <p className="mt-2 text-xs text-main-danger">
                      {errors.newPassword.message}
                    </p>
                  )}
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium text-main-secondary"
                  >
                    Confirm password
                  </label>
                  <div className="mt-1">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      {...register("confirmPassword", {
                        required: "This field is required",
                        validate: validateConfirmPassword,
                        minLength: {
                          value: 6,
                          message: "Minimum length is 6 characters",
                        },
                        maxLength: {
                          value: 16,
                          message: "Maximum length is 16 characters",
                        },
                      })}
                    />
                  </div>
                  {errors.confirmPassword && (
                    <p className="mt-2 text-xs text-main-danger">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
                <div className="col-span-full flex justify-end">
                  <button type="submit" className="btn-primary">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
